import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthContext';
import { ThemeProvider, createTheme } from '@mui/material/styles';


// const theme = createTheme({
//   palette: {
//     mode: 'dark', 
//   },
//   // typography: {
//   //   fontFamily : 'Public Sans Variable',
//   //   h4: {
//   //     fontWeight: 700,
//   //   },
//   // },
//   palette: {
//     primary: {
//       main: '#8EA604',
//       secondary: '#FFB000'
//     },
//   },
// });
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#8EA604',
      contrastText: "#fff",
      secondary: '#FFB000'
    },
    secondary : {
      main: '#AC4C34',
      contrastText: "#fff",
    }
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </AuthProvider>
//  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
