import React, { useState, useEffect } from 'react';
import { Avatar } from '@mui/material';
import { Skeleton } from '@mui/material';
import { useApi } from '../hooks/useApi';
const UserAvatar = ({ username, fresh }) => {
  
    const [avatar, setAvatar] = useState(null);
    const jApi = useApi();

    useEffect(() => {
        const fetchAvatar = async () => {
            try {
                //On loggout username is null - don't load it
                if(username){
                    if(fresh){
                        const response = await jApi.request("POST", "delegated/photo")
                        setAvatar(response.data)
                    } 
                    else {
                        const response =  await jApi.request('GET',`cma/user/avatar/${username}`);
                        const data = response.data;
                        setAvatar(data);
                    }
                }
            } catch (error) {
                setAvatar("??");
            }
        };

        fetchAvatar();
    }, [username]);

  return (
    <>
        {avatar ? (
            <>
            
                {avatar.avatar ? 
                    <Avatar src={`data:image/png;base64, ${avatar?.avatar}`} ></Avatar>
                     :  <Avatar  >{avatar?.initials}</Avatar>
                }
            </>
        ) : (
            <Skeleton variant="circular" width={40} height={40} />
        )}
    </>
  );
};

export default UserAvatar;
