import React, { useState, useEffect, useContext  } from 'react';
import axios, { AxiosError } from 'axios';
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia';
import denied from './denied.gif'
import lavalamp from './lavalamp.gif'
import Container   from "@mui/material/Container"
import { AuthContext } from '../../AuthContext';

//import { useApi } from '../../hooks/useApi'
import { useNavigate } from "react-router-dom"
import LinearProgress from '@mui/material/LinearProgress';
import pkceChallenge from "pkce-challenge";

function OAuth2Process () {

    const apiClient = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      });

    //const jApi = useApi();
    const navigate = useNavigate();

    const { signIn } = useContext(AuthContext);

    const [heading, setHeading] = useState("Authenticating User")

    const [isloading, setIsLoading] = useState(true);
    const [msError, setMsError] = useState(null)
    const [errorMessage, setErrorMessage] = useState();
    const [info, setInfo] = useState(null);

    const fetchInfo = (async () => {
        try {

            setErrorMessage("")
            const url = window.location.href
            const urlParams = new URLSearchParams(url.split('#')[1]);

            if(urlParams.get('error') != null){
                setMsError({
                    error : urlParams.get('error'),
                    error_description : urlParams.get('error_description')
                });
                return;
            }

            const idToken = urlParams.get('id_token');
            const code = urlParams.get('code');
            const returnUrl = urlParams.get('state');
            let response;

            try{
                const TokenUrl = `auth/token_from_aad?token=${idToken}`
                response = await apiClient.get(TokenUrl)
            } catch(ex) {
                if(!ex.response){
                    setErrorMessage("Connection Error")
                    return
                }
                if(ex.response.status === 401) {
                    try{
                        setHeading("Registering User")
                        // If status code is 401, call the auth/bms_register endpoint with the token
                        const bmsRegisterUrl = `auth/bms_register?token=${idToken}`;
                        response = await apiClient.post(bmsRegisterUrl,null);
                   } catch(ex) {
                        setErrorMessage(ex.toString())
                        return;
                    }
                } else {
                    setErrorMessage(ex.toString());
                    return;
                }
            }

            // const pkce = await pkceChallenge(128);
            // //console.log(pkce)

            //Refresh the Microsoft Tokens
            const accessToken = response.data.access_token
            try{
                setHeading("Getting Token")
                apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
                const msUrl = `auth/microsoft_token`;
                const urlp = window.location.href
                const parsedUrl = new URL(urlp);
                const return_url = parsedUrl.origin + '/auth/oauth2process'
                const responseMicrosoft = await apiClient.post(`auth/microsoft_token`, {code : code, return_url : return_url});
            } catch (ex) { }


            //Set the Default Account
            sessionStorage.setItem('account',response.data.user_info.accounts[0]);
            delete response.data.user_info.avatar;
            signIn(response.data.access_token, response.data.access_expire, response.data.refresh_token, response.data.refresh_expire,response.data.user_info);
            navigate(returnUrl)
   
        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
            setIsLoading(false);
        }
    });

    useEffect(() => {
        fetchInfo();
    }, []);  



    return(


        <Container component="main" maxWidth="xs" sx={{mt:2}}>  

            {errorMessage ? 
                <Typography variant="h6" gutterBottom color="#F44336">
                 Access Denied : {errorMessage}
                </Typography>: null }
            { msError ? <>
                <Typography variant="h6" gutterBottom color="#F44336">{msError.error}</Typography>
                <Typography variant="h6" gutterBottom color="#F44336">{msError.error_description}</Typography></>: null }

            {isloading ? <>
                <Typography variant="h6" gutterBottom >{heading}</Typography>
                <LinearProgress />
            </>: null}

          <Card sx={{ maxWidth: 545, mt : 2 }}>
        <CardMedia
            image={errorMessage ? denied :lavalamp}
            sx={{ height: 240 }}
        /></Card>
        

         </Container>  

     
    )
}

export default OAuth2Process