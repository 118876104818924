import React, { useState, useEffect, useContext , useCallback } from 'react';

import { Link } from 'react-router-dom';
import {  Breadcrumbs, Container, LinearProgress, IconButton, Grid, Paper, Stack,  Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { useApi } from '../../hooks/useApi'
import FolderImage from "../../media/folder.ico"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteButton from '../../components/DeleteButton';

import FileIcon from '../../components/FileIcon'

import FloppyImage1 from "../../media/floppy-1.png"
import FloppyImage2 from "../../media/floppy-2.png"
import FloppyImage3 from "../../media/floppy-3.png"
import FloppyImage4 from "../../media/floppy-4.png"
import FloppyImage5 from "../../media/floppy-5.png"

import { random } from 'lodash';

function Index () {
    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);

    const [folderStack, setFolderStack] = useState([]);

    const [account, setAccount] = useState(null);

    const [showActions, setShowActions] = useState(false)

    // Array of floppy images
    const floppyImages = [FloppyImage1, FloppyImage2, FloppyImage3,  FloppyImage4, FloppyImage5];
    // Randomly select a floppy image
    const [randomFloppyImage, setRandomFlopyyImage] = useState(null)
    
    
    const fetchData = async () => {
        try {

            setData([]);
            setIsLoading(true)
            setErrorMessage("")

            if(!randomFloppyImage){
                setRandomFlopyyImage(floppyImages[random(0, floppyImages.length - 1)])
            }

            let selected_account = sessionStorage.getItem('account');

            const urlAccount = "cma/account?code="+selected_account
            const responseAccount = await jApi.request('GET', urlAccount);
            setAccount(responseAccount.data[0]);

            let url = 'office365/sharepoint_cma/account/' + selected_account
            const selectedFolder = folderStack.length > 0 ? folderStack[folderStack.length - 1] : null;

            //if/else requried so the upload doesnt' flicker after file upload/delete
            if (selectedFolder) {
                if(selectedFolder.name != "Contracts") {
                    setShowActions(true);
                }
                else {
                    setShowActions(false);
                }
                url = 'office365/sharepoint_cma/account/' + selected_account + '/item/' + selectedFolder.id
            } else {
                setShowActions(false)
            }

            const response = await jApi.request('GET', url);

            const filteredData = response.data.filter(item => item.name !== "Signatures");
            setData(filteredData);
            
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, [folderStack]); 


    
    const deleteFile = async (fileId) => {
        try {

            setData([]);
            setIsLoading(true)

            let selected_account = sessionStorage.getItem('account');
            const url = 'office365/sharepoint_cma/account/' + selected_account + '/item/' + fileId
            await jApi.request('DELETE', url);
            await fetchData()
  
            
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };



    const FileUpload = ({ onFileChange }) => {

        const uploadfiles = async (formData)  => {
            try {
                let selected_account = sessionStorage.getItem('account');
          
                let url = 'office365/sharepoint_cma/account/' + selected_account
                const selectedFolder = folderStack.length > 0 ? folderStack[folderStack.length - 1] : null;
                if (selectedFolder) {
                  url = 'office365/sharepoint_cma/account/' + selected_account + '/item/' + selectedFolder.id
                }

                // Get an array of files from FormData
                const files = formData.getAll('files');

                // Loop through each file and make separate requests
                for (const file of files) {
                    const singleFileFormData = new FormData();
                    singleFileFormData.append('file', file); // Append single file to FormData
                    // Make request for each file
                    await jApi.request("POST", url, singleFileFormData);
                }                
    
                //await jApi.request("POST", url, formData)
                await fetchData();
            } catch (error)
            {
                setErrorMessage(error.toString())
            } finally
            {
                setIsLoading(false)
            }

        }


        const onDrop = useCallback((acceptedFiles) => {

            if (onFileChange) {
            onFileChange(acceptedFiles);
          }
       
        //   const file = acceptedFiles[0];
        //   const formData = new FormData();
        //   formData.append('file', new File([file], file.name, { type: file.type }));
        const formData = new FormData();
        acceptedFiles.forEach(file => {
          formData.append('files', file); // Append each file to the formData with the name 'files'
        });
         
          try {
            setData([]);
            setIsLoading(true)
                      
            uploadfiles(formData)
            
          } catch (error) {
          }
        }, [onFileChange]);
      
        const { getRootProps, getInputProps } = useDropzone({ onDrop });
      
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} multiple />
            <Paper elevation={4} sx={{ p: 3, textAlign: 'center', cursor: 'pointer' }}>
              <CloudUploadIcon sx={{ fontSize: 40 }} />
              <Typography variant="body1">Drag 'n' drop some files here, or click to select files</Typography>
            </Paper>
          </div>
        );
      };


    return(

        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">File Center</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="55" height="55" src={randomFloppyImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '15px'}}>File Center</Typography>
                        
                    </Stack>        
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }
                </Grid>       

                    <Grid item  xs={12} sm={12} md={12} pb={3} >
                        {account ? 
                        <Breadcrumbs aria-label="breadcrumb" p={1}  >
                            <Stack direction="row" spacing={1}>
                                <img width="30" height="30" src={FolderImage} alt="Logo" sx={{paddingTop: '10px'}}  />
                                <Typography variant='subheading1' 
                                    sx={{
                                        cursor: 'pointer',
                                        color: (theme) => theme.palette.text.secondary, 
                                        paddingTop : '3px',
                                        textDecoration :  folderStack.length >= 1 ? 'underline' : ''
                                    }}
                                    onClick={() => {
                                        setIsLoading(true);
                                        setFolderStack([]);
                                    }}
                                > {account.name}</Typography>
                            </Stack>
                            {folderStack?.map((item, index) => (
                                <Typography  
                                    key={item.id}
                                    sx={{
                                        cursor: index !== folderStack.length - 1 ? 'pointer' : 'default',
                                        textDecoration :  index !== folderStack.length - 1 ? 'underline' : '',
                                        
                                    }}
                                    color="text.secondary"
                                    onClick={() => {
                                        if (index !== folderStack.length - 1) {
                                            setIsLoading(true);
                                            setFolderStack(prevStack => prevStack.slice(0, index + 1));
                                        }
                                    }}
                                >
                                    {item.name}
                                </Typography>
                                ))}
                                <IconButton size="small" variant="contained" color="primary" onClick={fetchData}  ><RefreshIcon></RefreshIcon></IconButton>
                        </Breadcrumbs>

                        : null   }
                        
                    </Grid>
                    <Grid item  xs={12} sm={12} md={12} pb={3} >
                    <TableContainer component={Paper} elevation={4} >
                <Table aria-label="simple table" >
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography variant='subtitle1' color="text.secondary">Name</Typography></TableCell>
                            <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Last Modified</Typography></TableCell>
                            { showActions ?
                            <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Actions</Typography></TableCell>
                            : null }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {data?.map((item, index) => (
                    <React.Fragment key={item.id}>
                        {item["@microsoft.graph.downloadUrl"] ? 
                        <TableRow  sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                            <TableCell  scope="row" onClick={() => window.open(item["@microsoft.graph.downloadUrl"])}  sx={{textDecoration: 'none !important', cursor: 'pointer'}}>
                            <FileIcon filename={item.name}  />    
                            </TableCell>

                            <TableCell align="right"><Typography variant='body1' color="text.primary"> {new Date(item.lastModifiedDateTime).toLocaleString()}</Typography></TableCell>
                            {showActions  ?
                            <TableCell align="right" >
                                <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>

                                    <DeleteButton onDelete={() => deleteFile(item.id)}></DeleteButton>

                                </Stack>

                            </TableCell>
                                   :null}
                                   </TableRow>
                        :
                        <TableRow  key={item.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row" 
                                        onClick={async () => {
                                            setIsLoading(true)
                                            setFolderStack(prevStack => [...prevStack, { id: item.id, name: item.name }]);
                                        }}  
                                        sx={{cursor: 'pointer'}}>
                                        <FileIcon filename={item.name} isFolder={true} />    
                            </TableCell>
                            
                            <TableCell align="right"><Typography variant='body1' color="text.primary">{new Date(item.lastModifiedDateTime).toLocaleString()}</Typography></TableCell>
                            {showActions  ?
                            <TableCell align="right">
                            </TableCell> : null }
                        </TableRow>
                    }</React.Fragment>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
</Grid>
          
                </Grid>
                { showActions && (
                    <Grid  >
                        <FileUpload></FileUpload>
                    </Grid>
                )}

          
                <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
            
        </Container>
    )
}

export default Index