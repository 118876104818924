import React, { useState } from 'react';
import { IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function DeleteButton({ onDelete }) {
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    
    if (confirmDelete) {
      onDelete(event);
    } else {
      setConfirmDelete(true);
      setTimeout(() => {
        setConfirmDelete(false);
      }, 3000); // Reset confirmation after 3 seconds
    }
  };

  return (
    <IconButton
      size="small"
      variant={confirmDelete ? 'contained' : 'outlined'}
      color={confirmDelete ? 'error' : 'primary'}
      onClick={(e) => handleClick(e)}
    ><Typography variant='subtitle1'>{confirmDelete ? 'Confirm' : null}</Typography>
      <DeleteIcon />
    </IconButton>
  );
}

export default DeleteButton;
