import {  useContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import { AuthContext } from '../AuthContext';

export const useApi = () => {

  const { signIn, signOut } = useContext(AuthContext);


  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  });


  // Define a variable to track the number of retries
  let retryCount = 0;

  // Extract the token update logic into a new function
  const updateTokens = async () => {
    const refreshToken = Cookies.get('refresh_token');
    if (refreshToken) {
      try{
        const refresh_url = '/auth/token_from_refresh?refresh_token=' + refreshToken
        const response = await apiClient.get(refresh_url);
        signIn(response.data.access_token, response.data.access_expire, response.data.refresh_token, response.data.refresh_expire,response.data.user_info);
      } catch (error) {
        throw error;
      }
    }
  };

  // Intercept 401 errors to refresh the access token
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry && retryCount === 0) {
        originalRequest._retry = true;

        try {
          await updateTokens(); // Call the new function to update tokens

          originalRequest.headers['Authorization'] = 'Bearer ' + Cookies.get('access_token');
          retryCount += 1; // Increment the retry count
          return apiClient(originalRequest);
        } catch (error) {
          throw error;
        }
      }
      return Promise.reject(error);
    }
  );

  const request = async (method, route, body, options) => {
    //try {
        const accessToken = Cookies.get('access_token');
        if (accessToken) {
            apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
        } else {
            //Handle when the access token expired but we still have a refresh token
            const refreshToken = Cookies.get('refresh_token');
            if(refreshToken) {
              //Case where user has been deleted.
              try {
                await updateTokens(); // Call the new function to update tokens
                const accessToken = Cookies.get('access_token');
                //console.log("SignOut *" + accessToken + "*")
                apiClient.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
              } catch (error){
                console.log("SignOut " + error.toString())
                signOut();
              }
            }
        }

        //Needed This 'if' to Handle the File Download options = { responseType: 'arraybuffer' }
        let response;
        if (method.toLowerCase() === "get"){
          response = await apiClient[method.toLowerCase()](route, options);
        }
        else {
          response = await apiClient[method.toLowerCase()](route, body, options);
        }
        return response;
    // } catch (error) {
    //   if(error.response.data){
    //     throw (error.response.data.error.message)
    //   } else {
    //     throw error;
    //   }
    // }
  };

  return { request };
};
