import React, { useState, useEffect, useContext  } from 'react';

import { Link } from 'react-router-dom';
import { Button, Breadcrumbs, Container, LinearProgress, IconButton, Grid, Paper, Stack,  Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import QuoteImage from "../../media/Lamp Poses-02-1.png"
import { useApi } from '../../hooks/useApi'

import UserAvatar from '../../components/UserAvatar'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CreateIcon from '@mui/icons-material/Create';

import { AuthContext } from '../../AuthContext';
import RefreshIcon from '@mui/icons-material/Refresh';
import FileIcon from '../../components/FileIcon';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';


function Index () {

    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);
    const [dataSigned, setDataSigned] = useState([])
    const { getUser } = useContext(AuthContext);


    const fetchData = async () => {
        try {

            setIsLoading(true);
            setErrorMessage(null);

            setData([]);
            setDataSigned([]);

            let selected_account = sessionStorage.getItem('account');
            const url = 'signeasy/envelope?account=' + selected_account
            const response = await jApi.request('GET', url);
            setData(response.data.files)

            const urlSigned = 'signeasy/envelope_signed?account=' + selected_account
            const responseSigned = await jApi.request('GET', urlSigned);
            setDataSigned(responseSigned.data.files)
            
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 


    const signatureRedirect = async (email, id) => {
        try {
            
            setErrorMessage(null);

            const urlhost = window.location.href
            const parsedUrl = new URL(urlhost);
            const redirect_url = parsedUrl.origin + '/signature';

            const url = 'signeasy/envelope/' + id + '/signing_url'
            const response = await jApi.request('POST', url, 
                {
                    "recipient_email": email,
                    "redirect_url": redirect_url,
                    "allow_decline": true   
                }
            );

            if(response.data.error_code){
                setErrorMessage(response.data.message)
            }
            else {
                //window.location.href = response.data['url']
                window.open(response.data['url'], '_blank');
            }

        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }

    
      const downloadEnvelop = async (id, name) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);

            const url = 'signeasy/envelope/' + id + '/download'

            const response = await jApi.request('GET',  url, null, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const urlX = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = urlX;
            link.setAttribute('download', name);
            document.body.appendChild(link);

            link.click();

            // Clean up: remove the link and revoke the temporary URL
            document.body.removeChild(link);
            URL.revokeObjectURL(urlX);


        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }
    
    
    
    const downloadSigned = async (id, name) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);

            const url = 'signeasy/envelope_signed/' + id + '/signed_cert'

            const response = await jApi.request('GET',  url, null, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const urlX = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = urlX;
            link.setAttribute('download', name);
            document.body.appendChild(link);

            link.click();

            // Clean up: remove the link and revoke the temporary URL
            document.body.removeChild(link);
            URL.revokeObjectURL(urlX);
            
        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }
    return(
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Signature Center</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="60" height="60" src={QuoteImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '10px'}}>Signature Center</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                
                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1} >
                    <Stack direction="row">
                        <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>Documents awaiting signature</Typography>
                        <IconButton size="small" variant="contained" color="primary" onClick={fetchData}  ><RefreshIcon></RefreshIcon></IconButton>
                    </Stack>
                </Grid>

                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >

                <TableContainer component={Paper} elevation={4} >
                    <Table aria-label="simple table" >
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant='subtitle1' color="text.secondary">Signatory</Typography></TableCell>
                                <TableCell ><Typography variant='subtitle1' color="text.secondary">Document</Typography></TableCell>
                                
                                <TableCell align="right" ><Typography variant='subtitle1' color="text.secondary">Sender</Typography></TableCell>
                                <TableCell ><Typography variant='subtitle1' color="text.secondary"></Typography></TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {data?.map((item) => (
                            <TableRow
                            key={item.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'default', '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}
                            >

                            <TableCell component="th" scope="row">
                                    <Stack direction="column" spacing={1} alignItems="left">
                                        <Stack direction="row" alignItems="center" spacing={2}>
                                            <UserAvatar username={item.recipients[0].email} />
                                            <Typography variant='h6'>{item.recipients[0].first_name}</Typography>
                                        </Stack>
                                    <Typography variant='subheading1' color="text.secondary">{item.recipients[0].email}</Typography>
                                    </Stack>
                            </TableCell>
                            
                            <TableCell >
                                <Stack spacing={1} alignItems="left" onClick={() => downloadEnvelop(item.id, item.name)} sx={{cursor: 'pointer'}}>
                                    <FileIcon filename={item.name}  />    
                                    <Typography variant='subheading1' color="text.secondary">{item.message}</Typography>
                                    <Typography variant='subheading1' color="text.secondary">Expires:  {new Date(item.expiry_time * 1000).toLocaleDateString('en-US')}</Typography>
                                </Stack>
                            </TableCell>


                            <TableCell align='right' >
                                <Stack spacing={1} alignItems="end">
                                    <UserAvatar username={item.cc[0]} />
                                    <Typography variant='subheading1' color="text.secondary">{item.cc[0]}</Typography>
                                </Stack>
                            </TableCell>

                            <TableCell align='right' >
                                <Stack spacing={1} alignItems="end">
                                {item.recipients[0].email === getUser()?.username ?<Button size="small" variant="contained" color="secondary" endIcon={<CreateIcon/>} onClick={() => signatureRedirect(item.recipients[0].email, item.id)}>Sign</Button> : null }
                                </Stack>
                            </TableCell>



                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>

       
            </Grid> 
            { dataSigned.length > 0 ? 
                <>
                

            <Grid item  xs={12} sm={12} md={12} pb={1} pt={5} >
                    <Stack >
                        <Typography variant='h6' color="primary" sx={{paddingTop: '4px'}}>Signed Documents</Typography>
                        <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>The following documents have been signed and are waiting to be processed.  Once processed the files will be available in file center.  Thank you.</Typography>
                    </Stack>
                </Grid>



                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >

                <TableContainer component={Paper} elevation={4} >
                    <Table aria-label="simple table" >
                        <TableHead >
                            <TableRow >
                            <TableCell><Typography variant='subtitle1' color="text.secondary"></Typography></TableCell>
                                <TableCell><Typography variant='subtitle1' color="text.secondary">Signatory</Typography></TableCell>
                                <TableCell ><Typography variant='subtitle1' color="text.secondary">Document</Typography></TableCell>
                                
                                <TableCell align="right" ><Typography variant='subtitle1' color="text.secondary">Sender</Typography></TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {dataSigned?.map((item) => (
                             <React.Fragment key={item.id}>
                             <TableRow
                                 sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'default', '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}
                             >


                                    <TableCell component="th" scope="row">
                                    <CheckCircleOutlineRoundedIcon color="primary" fontSize="large"></CheckCircleOutlineRoundedIcon>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                         <Stack direction="column" spacing={1} alignItems="left">
                                             <Stack direction="row" alignItems="center" spacing={2}>
                                                 <UserAvatar username={item.pending_file.recipients[0].email} />
                                                 <Typography variant='h6'>{item.pending_file.recipients[0].first_name}</Typography>
                                             </Stack>
                                         <Typography variant='subheading1' color="text.secondary">{item.pending_file.recipients[0].email}</Typography>
                                         </Stack>
                                 </TableCell>
                                 
                                 <TableCell >
                                     <Stack spacing={1} alignItems="left" onClick={() => downloadSigned(item.id, item.name)} sx={{cursor: 'pointer'}}>
                                         <FileIcon filename={item.name}  />    

                                         <Typography variant='subheading1' color="text.secondary">Signed:  {new Date(item.last_modified_time * 1000).toLocaleDateString('en-US')}</Typography>
                                     </Stack>
                                 </TableCell>
                                 <TableCell align='right' >
                                     <Stack spacing={1} alignItems="end">
                                         <UserAvatar username={item.cc[0]} />
                                         <Typography variant='subheading1' color="text.secondary">{item.cc[0]}</Typography>
                                     </Stack>
                                 </TableCell>
   
                             </TableRow>
                             </React.Fragment>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>   
                </> 
                : null}            
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )
}


export default Index