import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container'
import { useApi } from './hooks/useApi'
import { Grid, Typography } from '@mui/material';

import LampImage from '../src/media/Lamp.png'

const RequireAuth = ({ children, roles }) => {

  const [userProfile, setUserProfile] = useState(null);
  const { user, isAuthenticated } = useContext(AuthContext);
  const [isloading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  const jApi = useApi();

  const navigate = useNavigate(); 

  useEffect(() => {
    // Check authentication when the component mounts
    if (!isAuthenticated()) {
      // Redirect to login page
      navigate("/auth/signin?returnUrl=" + encodeURIComponent(window.location.pathname))
      return
    }

    //Get the profile from server so users cant mess with it in the cookies.
    const setProfile = async () => {
      try {
        const response = await jApi.request('GET', "auth/profile", null)
        setUserProfile(response.data)
      } catch (error) {
        setErrorMessage("I'm sorry :( somethings gone bad: " + error.toString())
      }
    }
    setProfile();
  }, [user]); // Add "user" as a dependency


  useEffect(() => {
    if (userProfile !== null && userProfile.roles !== null) {
      const userRoles = userProfile.roles || [];
      const hasMatchingRole = userRoles.some(userRole => roles.includes(userRole));
      const isEmptyKeys = Object.keys(userProfile).length === 0;

      if ((!hasMatchingRole && roles.length > 0)  || isEmptyKeys) {
        navigate("/");
      } else {
        setIsLoading(false); // Set loading to false only if the roles match
      }
    }
  }, [userProfile]);


  if(isloading){
    return(
      <>
        {errorMessage ?
         <Container>
         <Grid
           container
           alignItems="center"
           justifyContent="center"
           direction="column"
           spacing={2}
           sx={{ height: 'calc(100vh-20vh)' }} // Set the height of the Grid to 100% of the viewport height
         >
           <Grid item>
             <img
               src={LampImage}
               alt="Error"
               style={{ width: '100%', maxWidth: '500px' }} // Set the width of the image to 100% and limit the maximum width to 500px
             />
           </Grid>
           <Grid item>
             <Typography variant="h6" color="error">
               {errorMessage}
             </Typography>
           </Grid>
         </Grid>
       </Container>
       


          :
          <LinearProgress /> 
        }
      </>
    )
  } 

  return children;
};

export default RequireAuth;
