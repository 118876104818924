import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import { useNavigate } from 'react-router-dom';
//import Link from '@mui/material/Link';
import { AuthContext } from '../AuthContext';
import Button from '@mui/material/Button'

import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
import imgCma from '../media/cma.svg'; // Change the path to your image
import Stack from '@mui/material/Stack'
import UserAvatar from './UserAvatar';


function Navbar() {
  const { user, signOut, isAuthenticated } = useContext(AuthContext);

 
  const [isauth, setIsAuth] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsAuth(isAuthenticated());
  }, [user]);


  const goSignIn = () => {
    navigate('/auth/signin');
  };

  return (
    <AppBar component="nav" sx={{ backgroundColor:  (theme) => theme.palette.primary.contrastText, color:  (theme) => theme.palette.text.primary  }}>
      <Toolbar style={{ justifyContent: 'space-between' }}>

        <Link to={isauth ? "/home" : "/"}>
          <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
            <img
              width="85"
              height="45"
              src={imgCma}
              alt="Logo"
            />
          </IconButton>
        </Link>

        {isauth ? (
          <Stack  direction="row" spacing={2}>
            <UserAvatar username={user?.username} fresh={true}></UserAvatar>

            <Button endIcon={ <LogoutTwoToneIcon />} size="medium" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2,  color: (theme) => theme.palette.primary.main, textTransform: "none"  }} onClick={() => signOut()} >
                Sign out
            </Button>
            </Stack>
        ) : (
            <Button endIcon={<LoginTwoToneIcon />} size="medium" edge="start" color="inherit" aria-label="menu" sx={{ mr: 2,  color: (theme) => theme.palette.primary.main, textTransform: "none"  }} onClick={goSignIn} >
                Sign in
            </Button>
        )}


      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
