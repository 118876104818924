import React, { useState, useEffect  } from 'react';
import { useApi } from '../../hooks/useApi'

import { Badge,  Card, CardHeader, IconButton, Grid, Stack, Typography } from '@mui/material'
import QuoteImage from "../../media/Lamp.png"
import { Link } from 'react-router-dom'; 
function TileAdminEnvelopeSigned () {

    const jApi = useApi();
    const [badge, setBadge] = useState(0)

    const [errorMessage, setErrorMessage] = useState(null)

    const fetchData = (async () => {
        try {
            setBadge(0)
            setErrorMessage("")
            const response = await jApi.request('GET', 'signeasy/envelope_signed');
            setBadge(response.data.count);
        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
        }
    });


    useEffect(() => {
        fetchData(); 
    }, []); 



    return(
      <>
      
        <Card elevation={3}>
            <CardHeader  component={Link} to="/admin/envelopesigned" sx={{textDecoration: 'none'}}
                avatar={
                    <Badge badgeContent={badge} color="primary">
                  <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                  <img
                    width="60"
                    height="60"
                    src={QuoteImage}
                    alt="Logo"
                  />
                  </IconButton>
                  </Badge>
                }
                title={
                    
                    <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                       Signed Documents
                    </Typography>

                }
                subheader=  {                  
                <Stack direction="row" spacing={1}>
                
                <Typography variant="body1" >Signed documents ready for processing.</Typography>
                </Stack>}
            />
        </Card>
        <Grid item sm={12}>
            <Typography  variant="subheading1" color="error" > {errorMessage}</Typography>
        </Grid> 
        </>
    )
}


export default TileAdminEnvelopeSigned