import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
// Create the auth context
const AuthContext = createContext();

// Create the auth provider component
const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null);

  // On component mount, check if user information is stored in cookies
  useEffect(() => {
    const userFromCookie = Cookies.get('user_info');
    if (userFromCookie) {
      //console.log("setting user")
      setUser(JSON.parse(userFromCookie));
    }
  }, []);

  const getUser = () => {
    const userFromCookie = Cookies.get('user_info');
    if (userFromCookie) {
      //console.log("setting user")
      return JSON.parse(userFromCookie);
    }
    return {};
  }

  const signIn = (access_token, access_expire, refresh_token, refresh_expire, user_info) => {
    // Perform authentication logic here
    setUser(user_info);
    Cookies.set('access_token', access_token, { expires: new Date(access_expire) });
    Cookies.set('refresh_token', refresh_token, { expires: new Date(refresh_expire) });
    Cookies.set('access_expire', access_expire, { expires: new Date(access_expire) });
    Cookies.set('refresh_expire', refresh_expire, { expires: new Date(refresh_expire) });
    Cookies.set('user_info', JSON.stringify(user_info), {expires: new Date(refresh_expire) });
  };

  const signOut = () => {
    // Clear the user data to indicate sign out
    setUser(null);
    // Remove the user information cookie
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    Cookies.remove('access_expire');
    Cookies.remove('refresh_expire');
    Cookies.remove('user_info');

    localStorage.clear()
    sessionStorage.clear()
  };

  const isAuthenticated = () => {
      //If the user has a valid refresh token they still have
      //access to the system.  
      const refreshToken = Cookies.get('refresh_token');
      if(refreshToken){
          const refreshTokenExpiration = Cookies.get('refresh_expire'); 
          const expirationDate = new Date(refreshTokenExpiration);
          if (expirationDate > new Date()) {
              return true;
          } 
      }
      return false;
  };
  
  return (
    <AuthContext.Provider value={{ user, getUser,  signIn, signOut, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
