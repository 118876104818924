import React, { useState, useEffect,  useContext  } from 'react';

import { Link } from 'react-router-dom';
import { Button, Breadcrumbs, Container, LinearProgress, IconButton, Grid, Paper, Stack, Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import QuoteImage from "../../../media/Lamp Poses-02-1.png"
import { useApi } from '../../../hooks/useApi'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';


import { AuthContext } from '../../../AuthContext';
import RefreshIcon from '@mui/icons-material/Refresh';

import DeleteButton from '../../../components/DeleteButton';
import FileIcon from '../../../components/FileIcon';

import UserAvatar from '../../../components/UserAvatar'

function Index () {

    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);
    const [showMine, setShowMine] = useState(false);


    const { getUser } = useContext(AuthContext);


    const fetchData = async () => {
        try {

            setIsLoading(true);
            setErrorMessage(null);

            setData([]);

            const url = 'signeasy/envelope'
            const response = await jApi.request('GET', url);

            // Extract unique account names
            const uniqueAccountNames = [...new Set(response.data.files.map(file => file.account_name))].sort().map(accountName => {
                const accountFiles = response.data.files.filter(file => file.account_name === accountName);
                const account = accountFiles.length > 0 ? accountFiles[0].account : null;
                return {
                    account_name: accountName,
                    account: account,
                    files: accountFiles
                };
            });
           
            setData(uniqueAccountNames)
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 


    const signatureRedirect = async (email, id) => {
        try {
            
            setErrorMessage(null);

            const urlhost = window.location.href
            const parsedUrl = new URL(urlhost);
            const redirect_url = parsedUrl.origin + '/signature';

            const user = getUser();
            const url = 'signeasy/envelope/' + id + '/signing_url'
            const response = await jApi.request('POST', url, 
                {
                    "recipient_email": email,
                    "redirect_url": redirect_url,
                    "allow_decline": true   
                }
            );
            console.log(user)
            console.log(response.data)

            if(response.data.error_code){
                setErrorMessage(response.data.message)
            }
            else {
                //window.location.href = response.data['url']
                window.open(response.data['url'], '_blank');
            }

        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }

    const removeEnvelope = async (id) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);

            const url = 'signeasy/envelope/' + id 
            const response = await jApi.request('DELETE', url);
            
            if(response.data.error_code){
                setErrorMessage(response.data.message)
            }
            else {
                await fetchData()
            }

        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }

    const removeSigned = async (id) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);

            const url = 'signeasy/envelope_signed/' + id 
            const response = await jApi.request('DELETE', url);
            
            if(response.data.error_code){
                setErrorMessage(response.data.message)
            }
            else {
                await fetchData()
            }

        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }
    


      const downloadEnvelop = async (id, name) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);

            const url = 'signeasy/envelope/' + id + '/download'

 
            const response = await jApi.request('GET', url, null, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const urlX = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = urlX;
            link.setAttribute('download', name);
            document.body.appendChild(link);

            link.click();

            // Clean up: remove the link and revoke the temporary URL
            document.body.removeChild(link);
            URL.revokeObjectURL(urlX);






        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }
    
    
    return(
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Signature Request</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="60" height="60" src={QuoteImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '10px'}}>Signature Request</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                
                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1} >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row">
                            <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>Documents awaiting signature</Typography>
                            <IconButton size="small" variant="contained" color="primary" onClick={fetchData}  ><RefreshIcon></RefreshIcon></IconButton>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <Button component={Link} to={`/admin/envelopeopen/request`} size="small" variant="outlined" color="primary">Create Signature Request</Button>
                            
                            {/* <Switch checked={showMine} onChange={() => setShowMine(!showMine)} color="primary" />
                            <Typography variant='subtitle1' color="text.secondary"  sx={{paddingTop: '4px'}} >Just Mine</Typography> */}
                        </Stack>
                    </Stack>
                </Grid>
                
                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >
                    <TableContainer component={Paper} elevation={4} >
                        <Table aria-label="simple table" >
                            <TableBody>
                            {data?.map((item, index) => (
                                <React.Fragment key={index}>
                                <TableRow color="primary" sx={{ backgroundColor: theme => theme.palette.grey[300] , '&:last-child td, &:last-child th': { border: 0 }, textDecoration : 'none !important' }}>
                                    <TableCell colSpan={5} component="th" scope="row"><Typography variant='body1' sx={{fontWeight: 'bold'}} color="text.primary">{item.account_name} - {item.account} ({item.files.length}) </Typography>
                                    </TableCell>
                               </TableRow>
                               
                               <TableRow >
                                    <TableCell sx={{ padding: '0 !important'}}><Typography pl={2} variant='subheading1' color="text.secondary">Signatory </Typography></TableCell>
                                    <TableCell  sx={{ padding: '0 !important'}}><Typography pl={2} variant='subheading1' color="text.secondary">Document </Typography></TableCell>
                                    <TableCell align='right'  sx={{ padding: '0 !important'}}><Typography pl={2} variant='subheading1' color="text.secondary">Sender&nbsp;&nbsp;&nbsp;&nbsp; </Typography></TableCell>
                                    <TableCell align='right'  sx={{ padding: '0 !important'}}><Typography pr={2} variant='subheading1' color="text.secondary"> </Typography></TableCell>
                               </TableRow>
                                {item.files.filter(row => !showMine || row.cc.includes(getUser().username))?.map((item, index) => (
                                    <React.Fragment key={index}>
                                    <TableRow
                                        key={item.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'default', '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}
                                    >

                                        <TableCell component="th" scope="row">
                                                <Stack direction="column" spacing={1} alignItems="left">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <UserAvatar username={item.recipients[0].email} />
                                                        <Typography variant='h6'>{item.recipients[0].first_name}</Typography>
                                                    </Stack>
                                                <Typography variant='subheading1' color="text.secondary">{item.recipients[0].email}</Typography>
                                                </Stack>
                                        </TableCell>
                                        
                                        <TableCell >
                                            <Stack spacing={1} alignItems="left" onClick={() => downloadEnvelop(item.id, item.name)} sx={{cursor: 'pointer'}}>
                                                <FileIcon filename={item.name}  />    
                                                <Typography variant='subheading1' color="text.secondary">{item.message}</Typography>
                                                <Typography variant='subheading1' color="text.secondary">Expires:  {new Date(item.expiry_time * 1000).toLocaleDateString('en-US')}</Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align='right' >
                                            <Stack spacing={1} alignItems="end">
                                                <UserAvatar username={item.cc[0]} />
                                                <Typography variant='subheading1' color="text.secondary">{item.cc[0]}</Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align='right'>
                                        <DeleteButton onDelete={() => removeEnvelope(item.id)}></DeleteButton>
                                        </TableCell>
                                    </TableRow>
                                    </React.Fragment>
                                ))}                            
                                </React.Fragment>   
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

                      
            </Grid> 
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )
}


export default Index