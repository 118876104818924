import React from 'react';
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Link } from 'react-router-dom'; // Import Link from React Router
import IconButton from '@mui/material/IconButton'
import FloppyImage1 from "../../media/floppy-1.png"
import FloppyImage2 from "../../media/floppy-2.png"
import FloppyImage3 from "../../media/floppy-3.png"
import FloppyImage4 from "../../media/floppy-4.png"
import FloppyImage5 from "../../media/floppy-5.png"

import { random } from 'lodash';
function TileFileCenter () {

    // Array of floppy images
    const floppyImages = [FloppyImage1, FloppyImage2, FloppyImage3, FloppyImage4, FloppyImage5];
    // Randomly select a floppy image
    const randomFloppyImage = floppyImages[random(0, floppyImages.length - 1)];
     
     
    return(
      
        <Card elevation={3}>
            <CardHeader  component={Link} to="/file" sx={{textDecoration: 'none'}}
                avatar={
                  <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                  <img
                    width="70"
                    height="70"
                    src={randomFloppyImage}
                    alt="Logo"
                  />
                  </IconButton>
                }
                title={
                    <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                        File Center
                    </Typography>
                }
                subheader="Important files."
            />
           
        </Card>
    )
}

export default TileFileCenter