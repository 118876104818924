import React, { useState, useEffect  } from 'react';
import { useApi } from '../../hooks/useApi'

import { Badge, Card, CardHeader, IconButton, Grid, Typography } from '@mui/material'
import FinanceImage from "../../media/Lamp Poses-03-1.png"
import { Link } from 'react-router-dom'; 

function TileQuoteCenter ({ account }) {

    const jApi = useApi();
    const [errorMessage, setErrorMessage] = useState(null)
    const [badge, setBadge] = useState(0)
    const [badgeColor, setBadgeColor] = useState('primary')

    const fetchData = (async () => {
        try {
            setBadge(0)
            setErrorMessage("")
            setBadgeColor('primary');

            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 120);

            // Format start_date as 'YYYY-MM-DD'
            var start_date = startDate.toISOString().slice(0,10);

            // Format end_date as 'YYYY-MM-DD'
            var end_date = new Date().toISOString().slice(0,10);

            const url = 'quickbooks/invoices?open_only=true&start_date='+start_date+'&end_date='+end_date+'&account='+account
            const response = await jApi.request('GET', url);
            var pastDueDate = new Date();

            // Filter response.data to get items where DueDate is less than or equal to pastDueDate
            var overdueItems = response.data.filter(item => new Date(item.DueDate) <= pastDueDate);

            // Get the number of overdue items
            var numberOfOverdueItems = overdueItems.length;

            if(numberOfOverdueItems > 0){
                setBadgeColor('error')
                setBadge(numberOfOverdueItems)
            }
            else {
                setBadge(response.data.length)
            }

        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
            
        }
    });

    useEffect(() => {
        fetchData(); 
    }, [account]); 

    return(
      <>
        <Card elevation={3}>
            <CardHeader  component={Link} to="/finance" sx={{textDecoration: 'none'}}
                avatar={
                    <Badge badgeContent={badge} color={badgeColor}>
                  <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                  <img
                    width="60"
                    height="60"
                    src={FinanceImage}
                    alt="Logo"
                  />
                  </IconButton>
                  </Badge>
                }
                title={
                    
                    <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                        Finance Center
                    </Typography>

                }
                subheader="Pay bills."
            />
        </Card>
        <Grid item sm={12}>
            <Typography  variant="subheading1" color="error" > {errorMessage}</Typography>
        </Grid> 
    </>
    )
}


export default TileQuoteCenter