
import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import {  Breadcrumbs, Chip, Container, LinearProgress,  IconButton, Grid, Paper, Stack, Skeleton, Switch, Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import { useApi } from '../../hooks/useApi'

import RefreshIcon from '@mui/icons-material/Refresh';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import { useParams } from 'react-router-dom';

function Detail() {
    
    const { id } = useParams();
    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);
    const [campaignName, setCampaignName] = useState(null);
    const [showPassed, setShowPassed] = useState(true);


    const fetchData = async () => {
        try {

            setData([])
            setCampaignName(null);
            setIsLoading(true);
            setErrorMessage(null);
            let selected_account = sessionStorage.getItem('account');;

            const response = await jApi.request(
              'GET',
              '/knowbe4/'+selected_account+'/training_enrollments?campaign_id=' + id
            );

            var data = response.data;
            // Check if response.data has items
            if (data.length > 0) {
                setData(data);
                setCampaignName(data[0].campaign_name + ', ' + data[0].module_name)
            } else {
                setData([]);
                setCampaignName('Unregistered Campaign')
            }

        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 

    function formatDate(dateString) {

        if(!dateString) { return ""}
        // Convert date string to a JavaScript Date object
        var date = new Date(dateString);
      
        // Array of month names
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
        // Extract month, day, and year
        var month = monthNames[date.getMonth()];
        var day = date.getDate();
      
        // Format the date
        var formattedDate = `${month} ${day}`;
      
        return formattedDate;
      }

      const StatusChip = ({ status }) => {
        if (status === "Passed") {
          return <Chip label={status} color="success" size="small" />;
        }
        if (status === "Not Started") {
          return <Chip label={status} color="info" size="small" />;
        }
        return <Chip label={status} color="warning" size="small" />;
      };

    return(
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  component={Link}  to={`/training`}   color="secondary" sx={{  paddingTop: '2px'}}>Training Center</Typography>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Training Campaign</Typography>
                        
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12} pb={1}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',}}>

                      <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '10px'}}>Training Campaign</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>

                <Stack direction="row" justifyContent="space-between" alignItems="center">
                            {isLoading ? <Skeleton width="250px" height={40}></Skeleton>:
                            <Stack direction="row" spacing={1}>
                                <Typography variant='subtitle1' color="text.secondary"  sx={{paddingTop: '4px'}} >{campaignName}</Typography>
                                <IconButton size="small" variant="contained" color="primary" onClick={fetchData}><RefreshIcon /></IconButton>
                            </Stack>
                            }
                                                    <Stack direction="row" spacing={1}>
                                                    <Typography variant='subtitle1' color="text.secondary"  sx={{paddingTop: '4px'}} >Not-Completed</Typography>
                                    <Switch checked={showPassed} onChange={() => setShowPassed(!showPassed)} color="primary" />
                               
                                     <Typography variant='subtitle1' color="text.secondary"  sx={{paddingTop: '4px'}} >All-Enrolled</Typography>
                                
                            
                            
                        </Stack>
                            </Stack>
                </Grid>
                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >
                    <TableContainer component={Paper} elevation={4} >
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant='subtitle1' color="text.secondary">User</Typography></TableCell>
                                    <TableCell  align="right"><Typography variant='subtitle1' color="text.secondary">Completed</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Time Spent</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Status</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data.filter(row => showPassed || row.status !== 'Passed')?.map((row, index) => (
                                <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}>
                                    <TableCell component="th" scope="row"><Typography variant='body1' color="text.primary">{row.user.first_name} {row.user.last_name} ({row.user.email}) </Typography></TableCell>
                                    <TableCell  align="right"><Typography variant='body1' color="text.primary">{formatDate(row.completion_date)}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='body1' color="text.primary">{row.time_spent > 0 ? `${Math.floor(row.time_spent/60)} minutes`: null}</Typography></TableCell>

                                    <TableCell align="right">
                                        <StatusChip status={row.status}></StatusChip>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>


            </Grid>
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )

}

export default Detail
