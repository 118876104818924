import { useState, useEffect, useContext } from "react"
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import LinearProgress from '@mui/material/LinearProgress';
import LogInIcon from '@mui/icons-material/Login';

import { useNavigate } from "react-router-dom"

import { AuthContext } from '../../AuthContext';

import MicrosoftIcon from '../../media/microsoft.png'
import ImageHello from "../../media/Lamp.png"
function SignIn () {

    const [errmsg, setErrMsg] = useState();
    const [isloading, setIsLoading] = useState(true);
    const { isAuthenticated, getUser } = useContext(AuthContext);
    const navigate = useNavigate();
    
    const onLogin = () => {
        setIsLoading(true)
        const url = window.location.href
        const nonce = crypto.getRandomValues(new Uint32Array(1))[0];
        const parsedUrl = new URL(url);
        const return_url = parsedUrl.origin + '/auth/oauth2process';
        const client_id = 'a38dc3ea-2542-4fe7-bda5-7af172d33f81'
        const returnPageUrl = parsedUrl.searchParams.get('returnUrl'); // Get returnUrl from query string
        const state = returnPageUrl ? encodeURIComponent(returnPageUrl) : encodeURIComponent("/home");
        const urlLogin = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${client_id}&response_type=id_token code&redirect_uri=${return_url}&response_mode=fragment&scope=user.read profile openid offline_access&state=${state}&nonce=${nonce}`
        window.location.href = urlLogin
    }

    useEffect(() => {
        if(isAuthenticated()){
            const user = getUser()
            sessionStorage.setItem('account',user.accounts[0]);
            navigate("/home")
        }
        setIsLoading(false);
    }, []); 

    return(        
                
        <Container component="main" maxWidth="xs" sx={{mt:2}}>  

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'primary.main', width: 75, height: 75}}  >
                <img
                    width="115"
                    height="115"
                    src={ImageHello}
                    alt="Logo"
                  />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign into My CMA
                </Typography>
                <Box  sx={{ mt: 0 }}>
            
                    <Button variant="contained" onClick={onLogin} startIcon={<img width={23} src={MicrosoftIcon} alt="Microsoft Logo" />}// Add the Microsoft logo icon here
                    sx={{ mt: 3, mb: 2 }}>Sign In With Microsoft</Button>  
                    { isloading ? <LinearProgress /> : <></>}
                    <Typography ariant="subtitle1" gutterBottom color="#F44336">
                    {errmsg}
                    </Typography>


                </Box>
            </Box>

        
        </Container>

    )
}

export default SignIn