import React, { useState, useEffect, useContext, useCallback  } from 'react';

import { Link } from 'react-router-dom';
import {Snackbar, Alert, FormControl, Select, MenuItem, InputLabel, Button, Breadcrumbs, Container, LinearProgress, IconButton, Grid, Paper, Stack, Skeleton, Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import QuoteImage from "../../../media/Lamp Poses-02-1.png"
import { useApi } from '../../../hooks/useApi'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


import CreateIcon from '@mui/icons-material/Create';

import { AuthContext } from '../../../AuthContext';
import RefreshIcon from '@mui/icons-material/Refresh';

import DeleteButton from '../../../components/DeleteButton';
import FileIcon from '../../../components/FileIcon';

import TextField from '@mui/material/TextField'

import Autocomplete from '@mui/material/Autocomplete'

import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';



function Request () {

    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    
    //Account Drop Down
    const [accounts, setAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();
    
    //Contact Data
    const [contacts, setContacts] = useState(null)
    const [selectedContact, setSelectedContact] = useState(null)
    const handleSelectedContactChange = (event) => {
        setSelectedContact(event.target.value);
    };
    const [isContactsLoading, setIsContactsLoading] = useState(false)

    //Message
    const [message, setMessage] = useState('')

    //File Data
    const [selectedFile, setSelectedFile] = useState(null)

    const { getUser } = useContext(AuthContext);

    const [snackBarState, setSnackBarState] = useState({
        open: false,
        message: ''
      });

    const fetchData = async () => {
        try {

            setIsLoading(true);
            setErrorMessage(null);
            setSelectedFile(null)
            
            const response = await jApi.request('GET', 'cma/account')
            const options = response.data.map(item => (
                item.name + ' (' + item.code + ')'
            ));
            setSelectedAccount(options[0])
            setAccounts(options)

        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 


    function extractCodeFromLabel(label) {
        const match = label.match(/\((.*?)\)/);
        return match ? match[1] : null;
      }

    const fetchContacts = async () => {
        try {

            setIsContactsLoading(true);
            setContacts(null);
            setSelectedContact(null);
            setErrorMessage(null);

            const urlContacts = "bms/contact/" + extractCodeFromLabel(selectedAccount)
            const responseContacts = await jApi.request('GET', urlContacts);
            setContacts(responseContacts.data);

        } catch (error) {
            setErrorMessage(error.toString())
        } finally {
            setIsContactsLoading(false)
        }
    };

    useEffect(() => {
        if(!selectedAccount) {
            return
        }
        setErrorMessage(null);
        setMessage("")
        setSelectedFile(null)
        fetchContacts()
    }, [selectedAccount]); 


    const handleOnChange = (event, newValue) => {
        if(!newValue) { return }
        setSelectedAccount(newValue)
    };

    
      const deleteFile = async (fileId) => {
        try {
            setIsLoading(true)
            const url = 'office365/sharepoint_cma/account/' + extractCodeFromLabel(selectedAccount) + '/item/' + fileId
            await jApi.request('DELETE', url);
            setSelectedFile(null);
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
            setIsLoading(false)
        }
    };

        
      const UniqueContactsDropdown = ({ contacts }) => {
        // Create an array of unique combinations of first name, last name, and email address
        const uniqueContacts = Array.from(new Set(contacts.map(contact => `${contact.firstName} ${contact.lastName} - ${contact.emails[0].emailAddress.toLowerCase()}`)));
      
        return (
          <FormControl fullWidth variant="standard">
            <InputLabel id="signatory-label">Select Signatory</InputLabel>
            <Select
              labelId="signatory-label"
              value={selectedContact || ""}
              fullWidth
              onChange={handleSelectedContactChange}
            >
              {uniqueContacts.map((contact, index) => (
                <MenuItem key={index} value={`${contact.split(' - ')[0]} - ${contact.split(' - ')[1]}`}>
                  {contact}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      };



      const handleSubmit = async () => {
        try {


            setIsLoading(true);

            const body = {
                "functionpath" : "cma/signature/Send-Quote.ps1",
                "splat" : {
                    'itemid' : selectedFile.id,
                    'account' : extractCodeFromLabel(selectedAccount),
                    'recipient_email' : selectedContact.split(" ").slice(-1)[0],
                    'recipient_name' : selectedContact.split(" ")[0],
                    'cc' : getUser().username,
                    'message' : message
                }
            }


            await jApi.request("POST", "cma/ves/queue", body)
            setSnackBarState(prevState => ({...prevState,  open: true, message : `A new 'Signature Request' will be created shortly.  ${selectedContact} will be notified by email that there is a document available for review.` }));
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };      

    const handleClose = () => {
        setSnackBarState(prevState => ({...prevState,  open: false }));
    };


    
    const FileUpload = ({ onFileChange }) => {

        const uploadfile = async (formData)  => {

            try {
                setIsLoading(true)


                const urlSP = "office365/sharepoint_cma/account/" +  extractCodeFromLabel(selectedAccount)
                const responseSP = await jApi.request('GET', urlSP);
                const signaturesId = responseSP.data.find(item => item.name === "Signatures")?.id;
                if(!signaturesId){
                    throw new Error("Signature folder not found.");
                }

                const urlSigFiles = "office365/sharepoint_cma/account/" + extractCodeFromLabel(selectedAccount) + '/item/' + signaturesId
                const response = await jApi.request("POST", urlSigFiles, formData)

                setSelectedFile(response.data)
              
            } catch (error) {
                setErrorMessage(error.toString())
            } finally {
                setIsLoading(false)
            }
         
        }
        const onDrop = useCallback((acceptedFiles) => {
            if (onFileChange) {
                onFileChange(acceptedFiles);
            }

            const file = acceptedFiles[0];
            const code = extractCodeFromLabel(selectedAccount); // Replace "your_code_here" with your actual code


            const randomNumber = Math.floor(100000 + Math.random() * 900000);
            const fileNameParts = file.name.split('.');
            const fileWithoutExtension = fileNameParts.slice(0, -1).join('.').replace(/[[]]/g, '');

            const extension = fileNameParts[fileNameParts.length - 1];
            const newFileName = `${fileWithoutExtension} [${code.toUpperCase()}] [${randomNumber}].${extension}`;
            
            const formData = new FormData();
            formData.append('file', new File([file], newFileName, { type: file.type }));

            
            try {
                
                uploadfile(formData)
                
            } catch (error) {
            
            } finally {
            
            }
        }, [onFileChange]);
        
        const { getRootProps, getInputProps } = useDropzone({ onDrop });
      
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Paper elevation={4} sx={{ p: 3, textAlign: 'center', cursor: 'pointer' }}>
              <CloudUploadIcon sx={{ fontSize: 40 }} />
              <Typography variant="body1">Drag 'n' drop your file here, or click to select file</Typography>
            </Paper>
          </div>
        );
      };



    return(
        <Container>
            <Snackbar open={snackBarState.open} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ "vertical": "bottom", "horizontal": "right" }}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ maxWidth: '500px' }}
                >
                    <div sx={{maxWidth: '500px'}}>
                    {snackBarState.message}
                    </div>
                   
                </Alert>
            </Snackbar>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography component={Link}  to={`/admin/envelopeopen`}  sx={{  paddingTop: '2px'}} color="secondary">Signature Request</Typography>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">New Signature Request</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="60" height="60" src={QuoteImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '10px'}}>New Signature Request</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                
                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1} >
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row">
                            <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>Create a request for digital signature</Typography>
                            <IconButton size="small" variant="contained" color="primary" onClick={fetchData}><RefreshIcon /></IconButton>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                        </Stack>
                    </Stack>
                </Grid>



                <Grid item  sx={{width: '100%'}} >
                        <Grid item xs={12} md={12}  sx={{width: '100%'}}>
                            { accounts  ? 
                            <Autocomplete 
                                variant="standard"
                                options={accounts || null}
                                value={selectedAccount || null}
                                renderInput={params => <TextField {...params} label="Select Account" variant="standard" placeholder='Select Account'/>}
                                onChange={handleOnChange}
                            />: <Skeleton variant="rectangular" height={45} /> }
                        </Grid>

                        {selectedAccount && contacts && !isContactsLoading ? 
                        <>
                            <Grid item xs={12} md={12}  sx={{width: '100%'}} pt={2}>
                                <UniqueContactsDropdown contacts={contacts}></UniqueContactsDropdown> 
                            </Grid>
                            <Grid item xs={12} md={12}  sx={{width: '100%'}} pt={2}>
                                    <TextField
                                    label="Message"
                                    value={message}
                                    variant='standard'
                                    onChange={(e) => setMessage(e.target.value)}
                                    fullWidth 
                                    />
                                </Grid>
                                </>      
                            :
                            <Grid item xs={12} md={12}  sx={{width: '100%'}} pt={2}>
                                <Stack spacing={2}>
                                    <Skeleton variant="rounded" height={55} pb={1}></Skeleton>
                                </Stack>
                            </Grid>
                        }

                        { selectedContact && (
                            <>
                        { selectedFile ? 
                            <>
                                <Grid item xs={12} md={12}  sx={{width: '100%'}} pt={2}>
                                    <Stack direction="row">
                                        <Typography variant="caption" display="block" color="text.secondary" gutterBottom>
                                            Selected Document
                                        </Typography>

                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={12}  sx={{width: '100%'}} pt={2}>
                                    <TableContainer component={Paper} elevation={4} >
                                        <Table aria-label="simple table" >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><Typography variant='subtitle1' color="text.secondary">Document Name</Typography></TableCell>
                                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Last Modified</Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                        
                                            <TableBody>
                                                    <TableRow 
                                                        sx={{ 
                                                            '&:last-child td, &:last-child th': { border: 0 }, 
                                                           
                                                        }} 
                                                        >
                                                        <TableCell  component="th" scope="row">

                                                            <Stack direction="row" spacing={2}>
                                                            <FileIcon filename={selectedFile.name}  />
                                                            </Stack>
                                                        </TableCell>

                                                        <TableCell align="right"><Typography variant='body1' color="text.primary"> {new Date(selectedFile.lastModifiedDateTime).toLocaleString()}</Typography></TableCell>
                                                        <TableCell align="right"><Typography variant='body1' color="text.primary"><DeleteButton onDelete={() => deleteFile(selectedFile.id)}></DeleteButton></Typography></TableCell>
          
                                                    </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    
                                </Grid>
                  
                            </>
                        :
                        <Grid item xs={12} md={12} pt={2}  sx={{width: '100%'}}>
                                            <Typography variant="caption" display="block" color="text.secondary" gutterBottom>
                                        Upload Document
                                    </Typography>
                        <FileUpload ></FileUpload>
                    </Grid>  

                            
                        }
                    </>)}
                                       <Grid item  xs={12} sm={12} md={12} pb={2} pt={2} >
                <Button disabled={!selectedContact || !selectedFile} size="small" variant="contained" color="primary" endIcon={<CreateIcon/>} onClick={handleSubmit} >Send Signature Request</Button> 
            </Grid>
                </Grid>
            </Grid>
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )}


    export default Request