import React from 'react';
import { Typography, Stack } from '@mui/material';
import ExcelImage from "../media/excel.ico"
import WordImage from "../media/word.ico"
import FileImage from "../media/file.ico"
import FolderImage from "../media/folder.ico"
import ZipImage from "../media/zip.ico"
import PowerPointImage from "../media/powerpoint.ico"
import VisioImage from "../media/visio.ico"
import AdobeImage from "../media/AcroRd32_2.ico"

function FileIcon({ filename, fontWeight, isFolder }) {
  const extension = filename.split('.').pop().toLowerCase();

  let icon;
  switch (extension) {
    case 'xls':
    case 'xlsx':
    case 'csv':
      icon = ExcelImage;
      break;
    case 'doc':
    case 'docx':
      icon = WordImage;
      break;
    case 'ppt':
    case 'pptx':
      icon = PowerPointImage;
      break;
    case 'vsd':
    case 'vsdx':
      icon = VisioImage;
      break;
    case 'pdf':
      icon = AdobeImage;
      break;
    case 'zip':
      icon = ZipImage;
      break;
    case 'folder':
      icon = FolderImage;
      break;
    default:
      icon = FileImage;
  }

  if(isFolder){
    icon = FolderImage;
  }

  return (
    <Stack direction="row" spacing={2}>
      <img width="25" height="25" src={icon} alt="Logo" sx={{ paddingTop: '10px' }} />
      <Typography variant='body1' color="text.primary" sx={{ fontWeight: fontWeight }}>{filename}</Typography>
    </Stack>
  );
}

export default FileIcon;
