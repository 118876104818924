import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import {  Breadcrumbs, Chip, Container, LinearProgress,  IconButton, Grid, Paper, Stack, Skeleton,  Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';


import { useApi } from '../../hooks/useApi'

import RefreshIcon from '@mui/icons-material/Refresh';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';


import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import EastIcon from '@mui/icons-material/East';


function Detail () {

    const { id } = useParams();
    const jApi = useApi();
    
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);
    const [activities, setActivities] = useState([]);


    const fetchData = async () => {
        try {

            setData([])
            setActivities([])
            setIsLoading(true);
            setErrorMessage(null);

            const response = await jApi.request(
              'GET',
              'bms/tickets/id/'+id
            );
            setData(response.data.result);

            const responseActivities = await jApi.request(
                'GET',
                'bms/tickets/id/'+id+'/activities'
            );
            setActivities(responseActivities.data.result)
            
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
        const month = (localDate.getMonth() + 1).toString().padStart(2, '0');
        const day = localDate.getDate().toString().padStart(2, '0');
        const year = localDate.getFullYear();
        let hours = localDate.getHours();
        const minutes = localDate.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // 0 should be converted to 12
        const formattedTime = `${hours}:${minutes} ${ampm}`;
        return `${month}/${day}/${year}, ${formattedTime}`;
      }



    return(
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  component={Link}  to={`/service`}   color="secondary" sx={{  paddingTop: '2px'}}>Service Center</Typography>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Service Item</Typography>
                        
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12} pb={1}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',}}>

                      <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '10px'}}>Service Item</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                <Grid item xs={12} sm={12} md={12}>

                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                            {isLoading ? <Skeleton width="150px" height={40}></Skeleton>:
                            <Stack direction="row" spacing={1}>
                                <Typography variant='subtitle1' color="text.secondary"  sx={{paddingTop: '4px'}} >{data?.ticketNumber}</Typography>
                                <IconButton size="small" variant="contained" color="primary" onClick={fetchData}><RefreshIcon /></IconButton>
                            </Stack>
                            }
                    </Stack>
                </Grid>
                {!isLoading && data ? 
                    <>
                    <Grid item xs={12} sm={12} md={12} >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant='h5' color="text.primary"  sx={{paddingTop: '4px'}} >{data?.title}</Typography>
                            <Chip label={data?.statusName} sx={{color: data?.statusForeColor, backgroundColor: data?.statusColor}} size="small" />  
                        </Stack>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={12} pt={1}>
                       <div>
                       <Accordion elevation={3}>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            >
                            Service Request Details
                            </AccordionSummary>
                            <AccordionDetails>
                            {data?.details ? parse(data?.details) : null}
                            </AccordionDetails>
                        </Accordion>
                        </div>
                    </Grid> 
                    <Grid item xs={12} sm={12} md={12} pt={1} >
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant='h6' color="text.primary"  sx={{paddingTop: '4px'}} >Ticket Activity</Typography>
                        </Stack>
                    </Grid>


                    <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >
                    <TableContainer component={Paper} elevation={4} >
                        <Table aria-label="simple table" >
 
                            <TableBody>
                            {activities?.map((row, index) => (
                                <React.Fragment key={index}>
                                <TableRow
      
                                sx={{ backgroundColor: '#f0f0f0', '&:last-child td, &:last-child th': { border: 0 }, '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}
                              
                                >
                                    <TableCell component="th" scope="row"><Typography variant='body1' sx={{fontWeight: 'bold'}} color="text.primary">{formatTimestamp(row.createdOn)} </Typography></TableCell>
                                    <TableCell ><Typography variant='body1' sx={{fontWeight: 'bold'}} color="text.primary">{row.activityType} {row.timeSpent > 0 ? row.timeSpent : null}</Typography></TableCell>
                                    <TableCell  align='right'><Typography variant='body1' sx={{fontWeight: 'bold'}} color="text.primary">
                                        {row.createdByName}
                                    </Typography></TableCell>

                     
                                </TableRow>
                                 <TableRow  colSpan={3}>
                                    {row.activityType === "Status Changed" ? 
                                     <TableCell colSpan={3}>
                                    <Stack direction="row" spacing={1}>
                                    <Chip label={row.notes} sx={{color: row.oldStatusFontColor, backgroundColor: row.oldStatusColor}} size="small" />  
                                         <EastIcon></EastIcon>
                                         <Chip label={row.internalNotes} sx={{color: row.newStatusFontColor, backgroundColor: row.newStatusColor}} size="small" />  
                                    </Stack>
                                    </TableCell>
                                    : 
                                     <TableCell colSpan={3}>{parse(row.notes)}</TableCell>
                                    }
                                 </TableRow>
                                 </React.Fragment >

                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </Grid>

 
                    </>
                   :null}

            </Grid>
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )
}
export default Detail