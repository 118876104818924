import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';

import SignIn from './pages/Auth/SignIn'
import OAuth2Process from './pages/Auth/OAuth2Process';
import RequireAuth from './RequireAuth';

import Navbar from './components/Navbar';
import Home from './pages/Home/Index'
import Announcement from "./pages/Announcement/Index"
import AnnouncementDetail from "./pages/Announcement/Detail"
import AnnouncementCreate from './pages/Announcement/Create'
import Finance from './pages/Finance/Index'
import Signature from "./pages/Signature/Index"
import Training from './pages/Training/Index'
import TrainingDetail from './pages/Training/Detail'
import Service from './pages/Service/Index'
import ServiceDetail from './pages/Service/Detail'
import File from './pages/File/Index'

import AdminEnvelopeOpen from './pages/Admin/EnvelopeOpen/Index'
import AdminSignatureRequest from './pages/Admin/EnvelopeOpen/Request'
import AdminEnvelopeSigned from './pages/Admin/EnvelopeSigned/Index'
import './App.css';


import logo from './logo.svg';

function App() {

  useEffect(() => {
    const adjustBodyPadding = () => {
      const appBarHeight = document.querySelector('nav').offsetHeight;
      document.body.style.paddingTop = `${appBarHeight}px`;

    };

    adjustBodyPadding();
    window.addEventListener('resize', adjustBodyPadding);

    return () => {
      window.removeEventListener('resize', adjustBodyPadding);
    };
  }, []);

  
  return (
  <Router>
    <Navbar />
      <div className="lines">
        <div className=" line lineRed "></div>
        <div className=" line lineGreen "></div>
        <div className=" line lineYellow "></div>
      </div>
      <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/auth/signin" element={<SignIn />} />
      <Route path="/auth/oauth2process" element={<OAuth2Process />} />

      <Route path={'/home'} element={
            <RequireAuth roles={[]} >
                <Home />
            </RequireAuth>
        }/>
        
      <Route path={'/announcement/create'} element={
            <RequireAuth roles={[]} >
                <AnnouncementCreate />
            </RequireAuth>
        }/>

         <Route path={'/announcement'} element={
            <RequireAuth roles={[]} >
                <Announcement />
            </RequireAuth>
        }/> 
         <Route path={'/announcement/detail/:id'} element={
            <RequireAuth roles={[]} >
                <AnnouncementDetail />
            </RequireAuth>
        }/>  
        <Route path={'/file'} element={
            <RequireAuth roles={[]} >
                <File />
            </RequireAuth>
        }/>   
             
        <Route path={'/signature'} element={
            <RequireAuth roles={[]} >
                <Signature />
            </RequireAuth>
        }/>     

        <Route path={'/finance'} element={
            <RequireAuth roles={[]} >
                <Finance />
            </RequireAuth>
        }/>  

        <Route path={'/training'} element={
            <RequireAuth roles={[]} >
                <Training />
            </RequireAuth>
        }/>   
        <Route path={'/training/detail/:id'} element={
            <RequireAuth roles={[]} >
                <TrainingDetail />
            </RequireAuth>
        }/>  
        <Route path={'/service'} element={
            <RequireAuth roles={[]} >
                <Service />
            </RequireAuth>
        }/>   
        <Route path={'/service/detail/:id'} element={
            <RequireAuth roles={[]} >
                <ServiceDetail />
            </RequireAuth>
        }/>  
        <Route path={'/admin/envelopeopen'} element={
            <RequireAuth roles={['admin']} >
                <AdminEnvelopeOpen />
            </RequireAuth>
        }/>       
        <Route path={'/admin/envelopeopen/request'} element={
            <RequireAuth roles={['admin']} >
                <AdminSignatureRequest />
            </RequireAuth>
        }/>
        <Route path={'/admin/envelopesigned'} element={
            <RequireAuth roles={['admin']} >
                <AdminEnvelopeSigned />
            </RequireAuth>
        }/>                                                   
    </Routes>
  </Router>
  );
}

export default App;
