import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Breadcrumbs, Container, LinearProgress, IconButton, Grid, Paper, Stack, Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import SpeakerImage from '../../media/Lamp Poses-01-1.png'

import { useApi } from '../../hooks/useApi'

import { AuthContext } from '../../AuthContext';
import RefreshIcon from '@mui/icons-material/Refresh';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import UserAvatar from '../../components/UserAvatar'
import DeleteButton from '../../components/DeleteButton'

import { useNavigate } from "react-router-dom"
function Index() {
    
    const jApi = useApi();
    
 
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
        
    const { getUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {

            setIsLoading(true);
            setErrorMessage(null);
            const user = getUser();
            let selected_account = sessionStorage.getItem('account');;
            const response = await jApi.request(
              'GET',
              'cma/announcement?account=' + selected_account + '&page_number=' + pageNumber + '&page_size=10'
            );
        
            let newData = response.data.data.map(item => {
                const hasRead = item.read.some(readItem => readItem.username === user.username);
                return { ...item, hasRead };
            });

            setData((prevData) => [...prevData, ...newData]);
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 


    
    const deleteAnnouncement = async (id, event) => {
        try {
            event.preventDefault();
            setData([]);
            setIsLoading(true)
            const url = 'cma/announcement/' + id
            await jApi.request('DELETE', url);
            const newArray = data.filter(item => item.id !== id);
            setData(newArray);
            
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };
    
    function formatDate(date_input) {
        if (date_input === null) {
          return "";
        }
      
        const date = new Date(date_input * 1000);
        const now = new Date();
        const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)); // Convert now to local time
        const diffTime = Math.abs(localDate - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
        if (diffDays < 1 && date.getDate() !== localDate.getDate()) {
          // If it's the same day and the dates are different
          return date.toLocaleString('en-US', { weekday: 'short' });
        } else if (diffDays <= 6) {
          // Within last 6 days
          return date.toLocaleString('en-US', { weekday: 'short' });
        } else {
          // Older than 6 days
          return date.toLocaleDateString('en-US');
        }
      }

  const extractText = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

    return(
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Announcement Center</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="60" height="60" src={SpeakerImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '15px'}}>Announcement Center</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row">
                            <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>Important updates from CMA Technology</Typography>
                            <IconButton size="small" variant="contained" color="primary" onClick={fetchData}><RefreshIcon /></IconButton>
                    </Stack>
                        <Stack direction="row" spacing={1}>
                            {getUser().roles?.includes('admin') ?
                                <Button component={Link} to={`/announcement/create`} size="small" variant="outlined" color="primary">Create Announcement</Button>
                            : null }
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                    <TableContainer component={Paper} elevation={4}>
                        <Table aria-label="simple table">
                        <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant='subtitle1' color="text.secondary">From</Typography></TableCell>
                                    <TableCell ><Typography variant='subtitle1' color="text.secondary">Announcement</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Created</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary"></Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data?.map((item) => (
                                
                                <TableRow
                                    key={item.id}
                                    
                                    onClick={() =>  navigate(`/announcement/detail/${item.id}`)}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}
                                    
                                                        > 
                                                       
                                    <TableCell component="th" scope="row">
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <UserAvatar username={item.sender_id} />
                                            <Typography variant='body1' sx={{ fontWeight: item.hasRead ? 'normal' : 'bold' }}>{item.sender_display_name}</Typography>
                                        </Stack>
                                    </TableCell>
                                    <TableCell style={{ maxWidth: '60%' }} >
                                        <Stack direction="row" spacing={2} alignItems="center">
                                            <Typography variant='subtitle1' color="text.primary" sx={{whiteSpace: 'nowrap', fontWeight: item.hasRead ? 'normal' : 'bold'}} >{item.subject}</Typography>
                                            <Typography variant='subtitle2' color="text.secondary" sx={{fontWeight: item.hasRead ? 'normal' : 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '450px'}}>{extractText(item.announcement)}</Typography>
                                        </Stack>
                                    </TableCell>
                                    
                                    <TableCell align="right">
                                     <Typography variant='body1'> {formatDate(item.created)}</Typography>
                                    </TableCell>
                                    {getUser().roles?.includes('admin') ?
                                        <TableCell align="right">
                                            <DeleteButton onDelete={(e) => deleteAnnouncement(item.id, e)}></DeleteButton>
                                        </TableCell>
                                    :null} 
                                </TableRow>
                                
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                <Typography variant='subheading1'color="error">{errorMessage}</Typography>
            </Grid>
        </Container>
    )
}



export default Index