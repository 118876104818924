import React, { useState, useEffect  } from 'react';
import {   Badge,  IconButton, Card, CardHeader,  Grid, Typography} from '@mui/material';
import { useApi } from '../../hooks/useApi'
import { Link } from 'react-router-dom'; // Import Link from React Router
import SpeakerImage from '../../media/Lamp Poses-01-1.png'

function TileAnnouncements ({ account }) {

    const jApi = useApi();

    const [errorMessage, setErrorMessage] = useState(null)
    const [unread, setUnread] = useState(0)
    
    const fetchData = (async () => {
        try {
            setUnread(0)
            setErrorMessage("")
            const response = await jApi.request('GET', 'cma/announcement/account/' + account + '/unread');
            setUnread(response.data.total_count);
        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
        }
    });

    useEffect(() => {
        fetchData(); 
    }, [account]); 
      
    return(
        <>
            <Card elevation={3}>
                <CardHeader  component={Link} to="/announcement" sx={{textDecoration: 'none'}}
                    avatar={
                        <Badge badgeContent={unread} color="primary" pt={4}   anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}>
                    <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                    <img
                        width="70"
                        height="70"
                        src={SpeakerImage}
                        alt="Logo"
                    />
                    </IconButton></Badge>
                    }
                    title={

                        <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                            Announcement Center
                        </Typography>
                        
                    }
                    subheader="Stay up to date on what's happening."
                />
            </Card>

            <Grid item sm={12}>
                <Typography  variant="subheading1" color="error" > {errorMessage}</Typography>
            </Grid> 

        </>
    )

}

export default TileAnnouncements
