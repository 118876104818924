import React, { useState, useEffect  } from 'react';

import { Link } from 'react-router-dom';
import { Button, Breadcrumbs, Chip, Container, LinearProgress, IconButton, Grid, Paper, Stack,  Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import FinanceImage from "../../media/Lamp Poses-03-1.png"
import { useApi } from '../../hooks/useApi'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import RefreshIcon from '@mui/icons-material/Refresh';
import FileIcon from '../../components/FileIcon';

function Index () {

    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);

    const fetchData = (async () => {
        try {

            setData([])

            setErrorMessage("")
            setIsLoading(true);
            let selected_account = sessionStorage.getItem('account');;
            var startDate = new Date();
            startDate.setDate(startDate.getDate() - 120);

            // Format start_date as 'YYYY-MM-DD'
            var start_date = startDate.toISOString().slice(0,10);

            // Format end_date as 'YYYY-MM-DD'
            var end_date = new Date().toISOString().slice(0,10);

            const url = 'quickbooks/invoices?open_only=true&start_date='+start_date+'&end_date='+end_date+'&account='+selected_account
            const response = await jApi.request('GET', url);
            setData(response.data);
        } catch (ex) {
            setErrorMessage(ex.toString() );
        } finally {
            setIsLoading(false);
        }
    });


    useEffect(() => {
        fetchData(); 
    }, []); 

    function formatDate(dateString) {
        // Convert date string to a JavaScript Date object
        var date = new Date(dateString);
      
        // Array of month names
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
        // Extract month, day, and year
        var month = monthNames[date.getMonth()];
        var day = date.getDate();
        var year = date.getFullYear();
      
        // Format the date
        var formattedDate = `${month} ${day}, ${year}`;
      
        return formattedDate;
      }
      function formatCurrency(amount) {
        return amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      }

      function DateChip({ dueDate }) {
        var currentDate = new Date();
        var inputDate = new Date(dueDate);
      
        if (currentDate > inputDate) {
          return <Chip label="Past Due" color="error" size="small" />;
        } else {
          return <Chip label="Open" color="primary" size="small" />;
        }
      }

      const downloadPdf = async (id, name) => {
        try {
            setIsLoading(true);
            setErrorMessage(null);

            const url = 'quickbooks/invoices/' + id + '/pdf'
            const response = await jApi.request('GET', url, null, { responseType: 'arraybuffer' });
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            const urlX = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = urlX;
            link.setAttribute('download', name);
            document.body.appendChild(link);

            link.click();

            // Clean up: remove the link and revoke the temporary URL
            document.body.removeChild(link);
            URL.revokeObjectURL(urlX);

        }catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    }
          

    return (
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Announcement Center</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="60" height="60" src={FinanceImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '15px'}}>Finance Center</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row">
                            <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>Pay bills</Typography>
                            <IconButton size="small" variant="contained" color="primary" onClick={fetchData}><RefreshIcon /></IconButton>
                    </Stack>
                    </Stack>
                </Grid>


                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >
                    <TableContainer component={Paper} elevation={4} >
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant='subtitle1' color="text.secondary">Invoice #</Typography></TableCell>
                                    <TableCell ><Typography variant='subtitle1' color="text.secondary">Due</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Amount</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Balance</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Status</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data?.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row"  onClick={() => downloadPdf(row.Id, `${row.DocNumber}.pdf`)}  sx={{cursor: 'pointer'}}>
                                        
                                    <FileIcon filename={`${row.DocNumber}.pdf`} />
                                        </TableCell>
                                    <TableCell ><Typography variant='body1' color="text.primary">{formatDate(row.DueDate)}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='body1' color="text.primary">{formatCurrency(row.TotalAmt)}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='body1' color="text.primary">{formatCurrency(row.Balance)}</Typography></TableCell>
                                    <TableCell align="right">
                                        <Stack direction="row" spacing={2} justifyContent="flex-end" >
                                            <DateChip dueDate={row.DueDate} />
                                            <Button component={Link} to={row.InvoiceLink} target='_blank' size="small" variant="text" color="primary">Pay now</Button>

                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>



            </Grid>
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )
}


export default Index