

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, Container, LinearProgress, Grid, Stack, Skeleton, Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { useApi } from '../../hooks/useApi'
import { useParams } from 'react-router-dom';
import UserAvatar from '../../components/UserAvatar';
import parse from 'html-react-parser';

function Detail() {
    
    const { id } = useParams();
    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);
   
    const fetchData = async () => {
        try {

            setIsLoading(true);
            setErrorMessage(null);
            const response = await jApi.request(
              'GET',
              'cma/announcement/' + id
            );
            setData(response.data);
        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 

    function formatDate(date_input) {
        if (date_input === null) {
          return "";
        }
      
        const date = new Date(date_input * 1000);
        const now = new Date();
        const localDate = new Date(now.getTime() - (now.getTimezoneOffset() * 60000)); // Convert now to local time
        const diffTime = Math.abs(localDate - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      
        if (diffDays < 1 && date.getDate() !== localDate.getDate()) {
          // If it's the same day and the dates are different
          return date.toLocaleString('en-US', { weekday: 'short' });
        } else if (diffDays <= 6) {
          // Within last 6 days
          return date.toLocaleString('en-US', { weekday: 'short' });
        } else {
          // Older than 6 days
          return date.toLocaleDateString('en-US');
        }
      }


    return(
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  component={Link}  to={`/announcement`}   color="secondary" sx={{  paddingTop: '2px'}}>Announcement Center</Typography>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Announcement</Typography>
                        
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12} pb={1}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',}}>

                        {isLoading ? <Skeleton width="50%" height={40}></Skeleton>
                          :<Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '10px'}}>{data?.subject}</Typography>}
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>

                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1} >
                    {isLoading ? 
                        <Stack direction="row" alignItems="center" sx={{ width: '95%' }} spacing={2} >
                            <Skeleton variant="circular" width={40} height={40} />
                            <Skeleton variant="rectangular" width="25%" height={40} />
                        </Stack>
                        :
                        
                            <Stack direction="row" spacing={1} alignItems="center">
                                <UserAvatar username={data.sender_id} />
                                <Stack>
                                    <Stack direction="row" spacing={1}>
                                        <Typography variant='h6'> {data.sender_display_name}</Typography>
                                        <Typography variant='subtitle1' color="text.secondary">{'<' + data.sender_username + '>'}</Typography> 
                                    </Stack>
                                    <Typography variant='body1' color="text.secondary">{formatDate(data.created)}</Typography>
                                </Stack>
                            </Stack>      
                           
                       

                        }
                </Grid>
                <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                <div>
                          { data?.announcement ? parse(data?.announcement): null}

                          </div>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                <Typography variant='subheading1'color="error">{errorMessage}</Typography>
            </Grid>
        </Container>

    )
}


export default Detail