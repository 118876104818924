import React, { useState, useEffect  } from 'react';
import { useApi } from '../../hooks/useApi'
import { Badge, Card, CardHeader, IconButton, Grid, Typography } from '@mui/material'
import QuoteImage from "../../media/Lamp Poses-02-1.png"
import { Link } from 'react-router-dom'; 


function TileQuoteCenter ({ account }) {
    const jApi = useApi();
    const [badge, setBadge] = useState(0)

    const [errorMessage, setErrorMessage] = useState(null)

    const fetchData = (async () => {
        try {
            setBadge(0)
            setErrorMessage("")
            const response = await jApi.request('GET', 'signeasy/envelope?account=' + account);
            setBadge(response.data.count);
        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
            
        }
    });

    useEffect(() => {
        fetchData(); 
    }, [account]); 



    return(
      <>
      
        <Card elevation={3}>
            <CardHeader  component={Link} to="/signature" sx={{textDecoration: 'none'}}
                avatar={
                    <Badge badgeContent={badge} color="primary">
                  <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                  <img
                    width="60"
                    height="60"
                    src={QuoteImage}
                    alt="Logo"
                  />
                  </IconButton>
                  </Badge>
                }
                title={
                    
                    <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                        Signature Center
                    </Typography>

                }
                subheader="Review and sign documents."
            />
        </Card>
        <Grid item sm={12}>
            <Typography  variant="subheading1" color="error" > {errorMessage}</Typography>
        </Grid> 
                </>
    )
}


export default TileQuoteCenter