import React, { useState, useEffect, useRef, useContext  } from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router
import { useApi } from '../../hooks/useApi'
import Container from '@mui/material/Container'
import { Grid, Breadcrumbs, Typography, LinearProgress, IconButton, Skeleton } from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';


import Button from '@mui/material/Button';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import Stack from '@mui/material/Stack'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import { AuthContext } from '../../AuthContext';
import { useNavigate } from "react-router-dom"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SpeakerImage from '../../media/Lamp Poses-01-1.png'

function Create () {

    const jApi = useApi();
    const navigate = useNavigate();
    const { getUser } = useContext(AuthContext);
          

    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)

    const [quillValue, setQuillValue] = useState('');
    const [errorQuill, setErrorQuill] = useState(false)
    const [subject, setSubject] = useState("");
    const [errorSubject, setErrorSubject] = useState(false)

    const [account, setAccount] = useState(null);

    const modules = {
        toolbar: [
          [{ header: '1' }, { header: '2' }, { font: [] }],
          [{ size: [] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        },
      }
    
    const fetchData = async () => {
        try {
            setIsLoading(true)
            setErrorMessage("")
            let selected_account = sessionStorage.getItem('account');
            const urlAccount = "cma/account?code="+selected_account
            const responseAccount = await jApi.request('GET', urlAccount);
            setAccount(responseAccount.data[0]);

        } catch (error) {
          setErrorMessage(error.toString())
        } finally {
          setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchData();
    }, []); 


    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
  
    const onSubmit = async () => {
  
      try {
          setErrorMessage("")
  
          if(!quillValue.trim()){
              setErrorQuill(true)
          }
          if(!subject.trim()){
              setErrorSubject(true)
          }
  
          if(!quillValue.trim() ||!subject.trim() ){
              return
          }
          let selected_account = sessionStorage.getItem('account');;
          const body = {
              sender : getUser().username,
              account : selected_account,
              subject : subject,
              announcement : quillValue
          }
          const response = await jApi.request('POST', 'cma/announcement', body)
          navigate("/announcement")
   
      } catch (ex) {
          setErrorMessage(ex.toString());
      } finally {
          setIsLoading(false);
      }
    };
  
    const onSubjectChange = (e) => {
        const { name, value } = e.target;
        if(value.trim()){
            setErrorSubject(false)
        }
        else {
            setErrorSubject(true)
        }
        setSubject(value);
      };

      return(
        
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography component={Link}  to={`/announcement`}  sx={{  paddingTop: '2px'}} color="secondary">Announcement Center</Typography>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Create Announcement</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container   cstyle={{ height: '100%', overflowY: 'auto' }}>
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="50" height="55" src={SpeakerImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '15px'}}>Create Announcement</Typography>
                        
                    </Stack>        
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }
                </Grid> 


                <Grid item  xs={12} sm={12} md={12} pb={3} >
                        {account ? <Typography variant='subheading1' color="text.secondary" sx={{paddingTop : '3px',}}
                                    
                                > New Message for {account.name}</Typography>: <Skeleton></Skeleton> }
                </Grid>

                <Grid  item  xs={12} sm={12} md={12} >
                    <Stack spacing={2}>

                    
                    <FormControl sx={{ width: '100%'}}>
                        <TextField
                            required
                            variant='standard'
                            id="outlined-required"
                            label="Subject"
                            value={subject}
                            onChange={onSubjectChange}
                            error={errorSubject} 
                            />
                    </FormControl>
                    <Typography variant='subtitle1' color="text.secondary" >Message</Typography>
                    <ReactQuill  value={quillValue} onChange={setQuillValue} modules={modules}  theme="snow" 
                                    style={{ border: errorQuill ? '1px solid red' : '1px solid black' }}></ReactQuill>
                    <Button  variant="contained" onClick={onSubmit} startIcon={<AddTwoToneIcon />} sx={{ mb: 2, width: '150px' }}>Create</Button>  
                    </Stack>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                <Typography variant='subheading1'color="error">{errorMessage}</Typography>
            </Grid>
        </Container>
    )

}

export default Create