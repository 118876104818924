import React, { useState, useEffect, useContext } from 'react';
import { useApi } from '../../hooks/useApi'
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography'
import { AuthContext } from '../../AuthContext';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField'

import TileFileCenter from './TileFileCenter';
import Autocomplete from '@mui/material/Autocomplete'

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack'

import TileAnnouncments from './TileAnnouncements'
import { LinearProgress } from '@mui/material';
import TileSignatureCenter from './TileSignatureCenter';
import TileFinanceCenter from './TileFinanceCenter';
import TileTrainingCenter from './TileTrainingCenter';
import TileServiceCenter from './TileServiceCenter';
import TileAdminEnvelopeOpen from './TileAdminEnvelopeOpen'
import TileAdminEnvelopeSigned from './TileAdminEnvelopeSigned';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import IconButton from '@mui/material/IconButton'
import ImageHello from "../../media/Lamp.png"


function Index() {

    const jApi = useApi();

    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const { user, getUser } = useContext(AuthContext);

    const [accounts, setAccounts] = useState(null);

    const [selectedAccount, setSelectedAccount] = useState();

    const fetchData = async () => {
        try {
            setErrorMessage("")
            setIsLoading(true);
            const response = await jApi.request('GET', 'cma/account')
            const options = response.data
                .map(item => `${item.name} (${item.code})`)
                .sort((a, b) => a.localeCompare(b));
    
            setAccounts(options)

            let selected_account = sessionStorage.getItem('account');
            let selectedItem = options.find(option => option.includes("(" + selected_account + ")"));
            if(selectedItem){
                setSelectedAccount(selectedItem);
            } else {
                setSelectedAccount(options[0])
            }



        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(); // Fetch the first page initially
    }, []);

    function extractCodeFromLabel(label) {
        const match = label.match(/\((.*?)\)/);
        return match ? match[1] : null;
    }

    const handleOnChange = (event, newValue) => {
        if (!newValue) { return }
        setSelectedAccount(newValue)
        sessionStorage.setItem('account', extractCodeFromLabel(newValue));
    };

    return (
        <>
            {!selectedAccount ? 
                <>
                    { isLoading && (<LinearProgress />) }
                </>
            :

                <Container component="main" sx={{ mt: 1 }}  >


                    <Grid container spacing={2} alignItems="center" >
                        <Grid xs={12} md={6}>
                            <Stack direction="row">
                            <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                                <img
                                    width="60"
                                    height="60"
                                    src={ImageHello}
                                    alt="Logo"
                                />
                                </IconButton>
                                <Typography variant="h5" sx={{ pt: 4 }}>
                                    Hey {user?.display_name.split(" ")[0]}, What's shaking?
                                </Typography>

                            </Stack>
                        </Grid>
                        <Grid xs={12} md={6}>
                            {accounts ?
                                <Autocomplete
                                    variant="standard"
                                    options={accounts || null}
                                    value={selectedAccount || null}
                                    renderInput={(params) => <TextField {...params} label="Select Account" variant="standard" placeholder='Select Account' />}
                                    onChange={handleOnChange}

                                /> : <Skeleton variant="rectangular" height={45} />}
                        </Grid>
                    </Grid>
    
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid xs={12} sm={12} md={6}  >
                            <TileAnnouncments account={extractCodeFromLabel(selectedAccount)}></TileAnnouncments>
                        </Grid>
                        <Grid xs={12} sm={12} md={6} >
                            <TileFileCenter></TileFileCenter>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} >
                            <TileSignatureCenter account={extractCodeFromLabel(selectedAccount)}></TileSignatureCenter>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} >
                            <TileFinanceCenter account={extractCodeFromLabel(selectedAccount)}></TileFinanceCenter>
                        </Grid>
                        <Grid xs={12} sm={12} md={4} >
                            <TileTrainingCenter account={extractCodeFromLabel(selectedAccount)}></TileTrainingCenter>
                        </Grid>
                        <Grid xs={12} sm={12} md={5}  >
                            <TileServiceCenter account={extractCodeFromLabel(selectedAccount)}></TileServiceCenter>
                        </Grid>
                    </Grid>
 
                    {getUser().roles?.includes("admin") && (
                        <>
                            <Grid container spacing={2} alignItems="center">
                                <Grid  xs={12} md={6}>
                                    <Typography variant="h5" sx={{ pt: 4,}} color="text.primary">
                                       Admin Center
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container  spacing={2} sx={{mt:3}}>
                                <Grid xs={12} sm={12} md={5}  >
                                    <TileAdminEnvelopeOpen></TileAdminEnvelopeOpen>
                                </Grid>
                                <Grid xs={12} sm={12} md={5}  >
                                    <TileAdminEnvelopeSigned></TileAdminEnvelopeSigned>
                                </Grid>
                            </Grid>
                        </>
                    )}

                </Container>
            }
             <Container component="main" sx={{ mt: 1 }}  >
                <Grid xs={12} sm={12} md={6}  >
                        <Typography variant='subheading1' color="error">{errorMessage}</Typography>
                     </Grid>
             </Container>
        </>
    )

}

export default Index
