import React, { useState, useEffect  } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Chip, Container, LinearProgress, IconButton, Grid, Paper, Stack,  Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { useApi } from '../../hooks/useApi'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import RefreshIcon from '@mui/icons-material/Refresh';
import TrainingImage from "../../media/Lamp Poses-05-1.png"
import LampImage from '../../../src/media/Lamp.png'

function Index () {

    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);


    const [ohSnap, setOhSnap] = useState(false)

    const navigate = useNavigate();

    const fetchData = (async () => {
        try {
            setData([])
            setErrorMessage("")
            setIsLoading(true);
            let selected_account = sessionStorage.getItem('account');;
            const url = 'knowbe4/'+selected_account+'/training_campaigns'
            const response = await jApi.request('GET', url);
            setData(response.data);
        } catch (ex) {
            console.log(ex.response.data.error.message)
            if(ex.response.data.error.message){
                if(ex.response.data.error.message.toLowerCase().includes("missing api key")){
                    setOhSnap(true)
                } else {
                    setErrorMessage(ex.response.data.error.message)
                }
            } else {
                setErrorMessage(ex.toString() );

            }
        } finally {
            setIsLoading(false);
        }
    });


    useEffect(() => {
        fetchData(); 
    }, []); 
    
    function formatDate(dateString) {
        // Convert date string to a JavaScript Date object
        var date = new Date(dateString);
      
        // Array of month names
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
        // Extract month, day, and year
        var month = monthNames[date.getMonth()];
        var day = date.getDate();
             
        // Format the date
        var formattedDate = `${month} ${day}`;
      
        return formattedDate;
      }
    return (
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Training Center</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="60" height="60" src={TrainingImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '15px'}}>Training Center</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                

                { ohSnap ?
                         <Container>

                            <Grid item textAlign={"center"} pt={2}>
                             <Typography variant="h6" color="secondary">
                               Oh Snap, it doesn't appear that your organization is setup for Security Awareness Training!
                               
                             </Typography>
                             <Typography variant="h6" color="secondary">
                             Let's get a note over to your account manager.
                             </Typography>
                           </Grid>
                         <Grid
                           container
                           alignItems="center"
                           justifyContent="center"
                           direction="column"
                           spacing={2}
                           // Set the height of the Grid to 100% of the viewport height
                         >
                           <Grid item>
                             <img
                               src={LampImage}
                               alt="Error"
                               style={{ width: '100%', maxWidth: '300px' }} // Set the width of the image to 100% and limit the maximum width to 500px
                             />
                           </Grid>
 
                         </Grid>
                       </Container>
                 :
                 <>
                 <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                 <Stack direction="row" justifyContent="space-between" alignItems="center">
                     <Stack direction="row">
                         <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>Security awareness</Typography>
                         <IconButton size="small" variant="contained" color="primary" onClick={fetchData}><RefreshIcon /></IconButton>
                 </Stack>
                 </Stack>
             </Grid>
                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >
                    <TableContainer component={Paper} elevation={4} >
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant='subtitle1' color="text.secondary">Campaign</Typography></TableCell>
                                    <TableCell ><Typography variant='subtitle1' color="text.secondary">Name</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Duration</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Complete</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data?.map((row, index) => (
                                <TableRow
                                key={index}
                                onClick={() => navigate(`/training/detail/${row.campaign_id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}
                              
                                >
                                    <TableCell component="th" scope="row"><Typography variant='body1' color="text.primary">{row.name} </Typography></TableCell>
                                    <TableCell ><Typography variant='body1' color="text.primary">{row.content[0].name}</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='body1' color="text.primary">{formatDate(row.start_date)}- {formatDate(row.end_date)}</Typography></TableCell>

                                    <TableCell align="right">
                                        <Chip label={`${row.completion_percentage}%`} color="primary" size="small" />
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                </>
            }


            </Grid>
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )
}


export default Index