import React, { useState, useEffect  } from 'react';
import { useApi } from '../../hooks/useApi'

import {  Card, CardHeader, Chip, IconButton, Stack, Skeleton, Typography } from '@mui/material'
import TrainingImage from "../../media/Lamp Poses-05-1.png"
import { Link } from 'react-router-dom'; 
function TileTrainingCenter ({ account }) {

    const jApi = useApi();
    const [badge, setBadge] = useState(0)

    const fetchData = (async () => {
        try {
            setBadge(-1)
            const url = 'knowbe4/'+account+'/training_campaigns'
            const response = await jApi.request('GET', url);
            var currentDate = new Date();
            var year = currentDate.getFullYear();
            var monthNames = ["January", "February", "March", "April", "May", "June",
                              "July", "August", "September", "October", "November", "December"];
            var monthName = monthNames[currentDate.getMonth()];

            const monthly_training = `${monthName} ${year} Monthly Training`

            const item = response.data.find(obj => obj.name === monthly_training);

            if(item){
                setBadge(item.completion_percentage + '%')
            }
            else {
                setBadge(0 + '%')
            }

        } catch (ex) {
            setBadge(0 + '%')
        } finally {
        }
    });


    useEffect(() => {
        fetchData(); 
    }, [account]); 


    return(
      <>
      
        <Card elevation={3}>
            <CardHeader  component={Link} to="/training" sx={{textDecoration: 'none'}}
                avatar={
                    
                  <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                  <img
                    width="60"
                    height="60"
                    src={TrainingImage}
                    alt="Logo"
                  />
                  </IconButton>
                  
                }
                title={
                    
                    <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                        Training Center
                    </Typography>

                }
                subheader={
                    <Stack direction="row" spacing={1}>
                        {badge === -1 ? <Skeleton variant="circular" width={20} height={20} /> : 
                        <Chip label={badge} color="primary" size="small" />}
                        <Typography variant="body1" > complete this month
                    </Typography>
                    </Stack>
                }
            />
        </Card>

        </>
    )
}


export default TileTrainingCenter