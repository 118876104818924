import React, { useState, useEffect  } from 'react';
import { useApi } from '../../hooks/useApi'

import {  Chip, Card, CardHeader, IconButton, Grid, Stack, Skeleton, Typography } from '@mui/material'
import QuoteImage from "../../media/Lamp Poses-02-1.png"
import { Link } from 'react-router-dom'; 
function TileAdminEnvelopeOpen () {

    const jApi = useApi();
    const [badge, setBadge] = useState(0)
    const [errorMessage, setErrorMessage] = useState(null)

    const fetchData = (async () => {
        try {
            setBadge(0)
            setErrorMessage("")
            const response = await jApi.request('GET', 'signeasy/envelope');
            setBadge(response.data.count);
        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
        }
    });


    useEffect(() => {
        fetchData(); 
    }, []); 



    return(
      <>
      
        <Card elevation={3}>
            <CardHeader  component={Link} to="/admin/envelopeopen" sx={{textDecoration: 'none'}}
                avatar={
                  <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                  <img
                    width="60"
                    height="60"
                    src={QuoteImage}
                    alt="Logo"
                  />
                  </IconButton>
                }
                title={
                    
                    <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                        Signature Request
                    </Typography>

                }
                subheader=  {                  
                <Stack direction="row" spacing={1}>
                {badge === -1 ? <Skeleton variant="circular" width={20} height={20} /> : 
                <Chip label={badge} color="primary" size="small" />}
                <Typography  variant="body1" > document(s) pending signature.</Typography>
                </Stack>}
            />
        </Card>
        <Grid item sm={12}>
            <Typography  variant="subheading1" color="error" > {errorMessage}</Typography>
        </Grid> 
        </>
    )
}


export default TileAdminEnvelopeOpen