import React, { useState, useEffect  } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import {  Breadcrumbs, Chip, Container, LinearProgress,  IconButton, Grid, Paper, Stack,  Typography} from '@mui/material'
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';

import { useApi } from '../../hooks/useApi'


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import RefreshIcon from '@mui/icons-material/Refresh';
import ServiceImage from "../../media/Lamp Poses-04-1.png"



function Index () {

    const jApi = useApi();
    const [isLoading, setIsLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const fetchData = (async () => {
        try {

            setData([])

            setErrorMessage("")
            setIsLoading(true);
            let selected_account = sessionStorage.getItem('account');;

            const body = {
                "PageSize": 0,
                "PageNumber": 0,
                "Filter": {
                    "AccountCode": selected_account,
                    "QueueNames" : "Installs,Depot,Tier 2 Service Desk",
                    "StatusNames" : "Waiting for Scheduling,Administrative Review,Design Review,Waiting for Delivery,Scheduled,Waiting for Results,Waiting on Vendor,Ready to Work,Waiting for Parts,Assigned,Escalated,In Progress,Waiting For Customer,New"
                }
            }
            const response = await jApi.request('POST', 'bms/tickets/search', body);
         
            setData(response.data.result);
        } catch (ex) {
            setErrorMessage(ex.toString() );
        } finally {
            setIsLoading(false);
        }
    });


    useEffect(() => {
        fetchData(); 
    }, []); 
    
    function formatDate(dateString) {
        // Convert date string to a JavaScript Date object
        var date = new Date(dateString);
      
        // Array of month names
        var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      
        // Extract month, day, and year
        var month = monthNames[date.getMonth()];
        var day = date.getDate();
        var year = date.getFullYear();
      
        // Format the date
        var formattedDate = `${month} ${day}`;
      
        return formattedDate;
      }
    return (
        <Container>
            <Grid container spacing={0}  sx={{ pt: 3, pb:2,  alignContent: 'left', textAlign: 'left', }} >
                <Grid item xs={12}>
                    <Breadcrumbs aria-label="breadcrumb" >
                        <Stack direction="row" spacing={1}>
                        <Link to={`/home`}><HomeTwoToneIcon color="secondary"  sx={{verticalAlign: 'middle'}}></HomeTwoToneIcon> </Link>
                        <Typography component={Link}  to={`/home`}  sx={{  paddingTop: '2px'}} color="secondary">Home</Typography>
                        </Stack>
                        <Typography  sx={{  paddingTop: '2px'}} color="text.primary">Service Center</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid container  >
                <Grid item  xs={12} sm={12} md={12}>
                    <Stack direction="row" spacing={2} sx={{borderBottom : '1px solid grey',paddingLeft: '20px'}}>
                        <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                            <img width="60" height="60" src={ServiceImage} alt="Logo"/>
                        </IconButton>
                        <Typography variant='h5' sx={{color: (theme) => theme.palette.secondary.main,  paddingTop: '15px'}}>Service Center</Typography>
                    </Stack>    
                    {isLoading ? <LinearProgress sx={{ width: '100%' }} /> : null }  
                </Grid>
                
                <Grid item xs={12} sm={12} md={12} pb={1} pt={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction="row">
                            <Typography variant='subtitle1' color="text.secondary" sx={{paddingTop: '4px'}}>Open Service Items</Typography>
                            <IconButton size="small" variant="contained" color="primary" onClick={fetchData}><RefreshIcon /></IconButton>
                    </Stack>
                    </Stack>
                </Grid>


                <Grid item  xs={12} sm={12} md={12} pb={1} pt={1}  >
                    <TableContainer component={Paper} elevation={4} >
                        <Table aria-label="simple table" >
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography variant='subtitle1' color="text.secondary">Ticket Number</Typography></TableCell>
                                    <TableCell ><Typography variant='subtitle1' color="text.secondary">Title</Typography></TableCell>
                                    <TableCell><Typography variant='subtitle1' color="text.secondary">Status</Typography></TableCell>
                                    <TableCell align="right"><Typography variant='subtitle1' color="text.secondary">Opened</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {data?.map((row,index) => (
                                <TableRow
                                key={index}
                                onClick={() => navigate(`/service/detail/${row.id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer', '&:hover': { backgroundColor: '#f0f0f0' }, textDecoration : 'none !important' }}
                              
                                >
                                    <TableCell component="th" scope="row"><Typography variant='body1' color="text.primary">{row.ticketNumber} </Typography></TableCell>
                                    <TableCell ><Typography variant='body1' color="text.primary">{row.title}</Typography></TableCell>
                                    <TableCell >
                                    <Chip label={row.statusName} sx={{color: row.statusForeColor, backgroundColor: row.statusColor}} size="small" />  
                                    </TableCell>
                                    <TableCell align="right"><Typography variant='body1' color="text.primary">{formatDate(row.createdOn)}</Typography></TableCell>

                     
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>



            </Grid>
            <Typography ariant="subtitle1" gutterBottom color="error"> {errorMessage}</Typography>
        </Container>
    )
}


export default Index