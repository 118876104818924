import React, { useState, useEffect  } from 'react';
import {  Button, Badge,  IconButton, Chip, Card, CardHeader,  CardContent, Divider, Grid, LinearProgress, List, ListItem, ListItemText, ListItemAvatar, ListSubheader, Paper, Stack, Typography, Skeleton, Avatar } from '@mui/material';
import { useApi } from '../../hooks/useApi'
import UserAvatar from '../../components/UserAvatar';
import { Link } from 'react-router-dom'; // Import Link from React Router
import CampaignIcon from '@mui/icons-material/Campaign';
import SpeakerImage from '../../media/Lamp Poses-04-1.png'

function TileService ({ account }) {

    const jApi = useApi();
    const [errorMessage, setErrorMessage] = useState(null)
    const [openTicketCount, setOpenTicketCount] = useState(0)
    const [waitingTicketCount, setWaitingTicketCount] = useState(0)
    
    const fetchData = (async () => {
        try {
            setOpenTicketCount(-1)
            setWaitingTicketCount(0)
            setErrorMessage("")

            const body = {
                "PageSize": 0,
                "PageNumber": 0,
                "Filter": {
                    "AccountCode": account,
                    "QueueNames" : "Installs,Depot,Tier 2 Service Desk",
                    "StatusNames" : "Waiting for Scheduling,Administrative Review,Design Review,Waiting for Delivery,Scheduled,Waiting for Results,Waiting on Vendor,Ready to Work,Waiting for Parts,Assigned,Escalated,In Progress,Waiting For Customer,New"
                }
            }

            const response = await jApi.request('POST', 'bms/tickets/search', body);
            setOpenTicketCount(response.data.totalRecords)

            const waitingForCustomerCount = response.data.result.filter(item => item.statusName === "Waiting For Customer").length;
            setWaitingTicketCount(waitingForCustomerCount)

        } catch (ex) {
            setErrorMessage(ex.toString());
        } finally {
        }
    });


    useEffect(() => {
        fetchData(); 
    }, [account]); 

     
      
    return(
        <>
        
        <Card elevation={3}>
            <CardHeader  component={Link} to="/service" sx={{textDecoration: 'none'}}
                avatar={
                    
                    <IconButton edge="end" color="inherit" aria-label="logo" disableFocusRipple={true} disableRipple>
                    <img
                        width="60"
                        height="60"
                        src={SpeakerImage}
                        alt="Logo"
                    />
                    </IconButton>
                    
                  
                }
                title={
                    
                    <Typography component="h6" variant="h6" sx={{textDecoration: 'none', color: (theme) => theme.palette.secondary.main}}>
                        Service Center
                    </Typography>

                }
                subheader={
                    <Stack direction="row" spacing={1}>
                        {openTicketCount === -1 ? <Skeleton variant="circular" width={20} height={20} /> : 
                        <Chip label={openTicketCount} color="primary" size="small" />}
                        <Typography  variant="body1" > open tickets.</Typography>
                        {waitingTicketCount > 0 ?<> <Chip label={waitingTicketCount} color="warning" size="small" />
                        <Typography  variant="body1" > waiting on customer.</Typography></> : null}
                    </Stack>
                }
            />
        </Card>
        <Grid item sm={12}>
            <Typography  variant="subheading1" color="error" > {errorMessage}</Typography>
        </Grid> 
        </>
    )

}

export default TileService
